import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import { api_multipague_configuracao } from '../../../services/api';
import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import DataGrid from '../../../components/DataGrid';
import { MdClear } from 'react-icons/md';

import { FaSearch } from 'react-icons/fa';

import { MdRemoveRedEye } from 'react-icons/md';
import DataGridWrapper from '../../../components/DataGrid/DataGridWrapper';
import { toastComponentError } from '../../../components/Toast';
import { Button } from '@material-ui/core';

export default function AssociacaoBancosListar() {
  const history = useHistory();
  const theme = useTheme();

  const columns = [
    { field: 'id', headerName: 'ID', flex: 1, minWidth: 80 },
    { field: 'banco', headerName: 'Banco', flex: 3, minWidth: 200 },
    {
      field: 'editar',
      headerName: 'Visualizar',
      type: 'text',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      renderCell: event => {
        return (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              handleEditar(event.row);
            }}
            style={{ borderRadius: '20px' }}
          >
            <MdRemoveRedEye
              style={{ cursor: 'pointer' }}
              color={theme.palette.primary.main}
              size={20}
            />
          </Button>
        );
      },
    },
  ];

  const [rows, setRows] = useState([]);
  const [valorInput, setValorInput] = useState({});
  localStorage.setItem('valorIdDetalhes', valorInput);
  const [rowCount, setRowCount] = useState();
  const [rowsState, setRowsState] = React.useState({ page: 0 });
  const [loadButton, setLoadButton] = useState(false);
  const [id, setId] = useState('');
  const [banco, setBanco] = useState('');

  const URLFilter = `/banco?id=${id}&nome=${banco}`;

  function pesquisar(URLFilter, page) {
    setLoadButton(true);
    api_multipague_configuracao
      .get(URLFilter + `&page=${page}`, {
        headers: { Accept: 'application/paged+json' },
      })
      .then(response => {
        setLoadButton(false);
        const monitorFilter = response.data.content;
        const qtdItems = response.data.totalElements;

        if (monitorFilter.length) {
          const aquiTable = monitorFilter.map(data => ({
            ...data,
            id: data.id,
            banco: `${data.id} - ${data.nome}`,
            bancoId: data.id,
          }));
          setRows(aquiTable);
          setRowCount(qtdItems);
        } else {
          toastComponentError('Nenhum dado disponível para essa data!', theme);
        }
      })
      .catch(error => {
        setLoadButton(false);
        setRows([]);
        setRowCount(0);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  }

  const cleanFilters = () => {
    setId('');
    setBanco('');
    setRowsState({ page: 0 });
    pesquisar(`/banco?id=&nome=`, 0);
  };

  useEffect(() => {
    pesquisar(URLFilter, 0);
  }, []);

  const handleEditar = value => {
    localStorage.setItem('assBancoNome', value.banco);
    localStorage.setItem('assIdBanco', value.bancoId);
    localStorage.setItem('assEvento', value.eventoId);
    localStorage.setItem('assDescricao', value.evento);
    history.push('/backoffice/associacao-bancos-consulta');
  };

  const onPage = page => {
    setRowsState(prev => ({ ...prev, page }));
    pesquisar(URLFilter, page);
  };

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <TextField
            fullWidth
            label="ID"
            id="outlined-size-small"
            value={id}
            onChange={e => {
              setId(e.target.value);
            }}
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 12, md: 5, lg: 5 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="Banco"
            id="outlined-size-small"
            value={banco}
            onChange={e => {
              setBanco(e.target.value);
            }}
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 12, md: 5, lg: 5 },
      },
    ],
    searchButton: {
      searchButton: () => onPage(0),
    },
    cleanButton: {
      cleanButton: () => cleanFilters(),
    },
  };

  return (
    <DataGridWrapper
      rows={rows}
      columns={columns}
      loading={loadButton}
      pagination
      rowCount={rowCount}
      backButton
      pageSize={10}
      title="Associação de Bancos"
      crumb={[
        { link: '/backoffice/new-backoffice', name: 'Home' },
        { name: 'Configurações' },
      ]}
      {...rowsState}
      paginationMode="server"
      onPageChange={onPage}
      formData={filterInputs}
      minHeight="600px"
    />
  );
}
