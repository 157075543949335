import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { BsLockFill, BsUnlockFill } from 'react-icons/bs';
import { VscLoading } from 'react-icons/vsc';
import InputText from '../../components/InputText';
import Select from '../../components/Select';
import Button from '../../components/Buttons/ConfirmButton';
import { AlertPopUp } from '../../components/AlertPopUp';
import { api_multipague_configuracao } from '../../services/api';
import Wrapper from '../../components/Wrapper';
import { MdSearch, MdClear } from 'react-icons/md';
import {
  Container,
  ContainerFiltros,
  OptionsFilter,
  ContainerButtonFilter,
  LoadingIcon,
} from './styles';
import { InputLabel, useTheme, FormControl } from '@material-ui/core';
import DataGridWrapper from '../../components/DataGrid/DataGridWrapper';
import { toastComponentError } from '../../components/Toast';

export const DesbloqueioUser = () => {
  const formRef = useRef(null);
  const theme = useTheme();

  const columnsTable = [
    {
      field: 'nome',
      headerName: 'Nome',
      type: 'string',
      minWidth: 200,
      flex: 2,
    },
    {
      field: 'login',
      headerName: 'Login',
      type: 'string',
      minWidth: 110,
      flex: 2,
    },
    {
      field: 'is_locked',
      headerName: 'Bloquear/Desbloquear',
      flex: 2,
      minWidth: 110,
      disableClickEventBubbling: true,
      renderCell: event => {
        <LoadingIcon>
          <VscLoading size={24} color={theme.palette.primary.main} />
        </LoadingIcon>;

        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              cursor: 'pointer',
            }}
          >
            {event.row?.loading ? (
              <LoadingIcon>
                <VscLoading size={24} color={theme.palette.primary.main} />
              </LoadingIcon>
            ) : event.row.is_locked ? (
              <div onClick={() => actionPasswordUser('unlock', event.row)}>
                <BsLockFill color={theme.palette.primary.secondary} size={20} />
              </div>
            ) : (
              <div onClick={() => actionPasswordUser('lock', event.row)}>
                <BsUnlockFill color={theme.palette.primary.main} size={20} />
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: 'lock_date',
      headerName: 'Data de bloqueio',
      minWidth: 110,
      flex: 2,
    },
  ];

  const [rowsTable, setRowsTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [situacao, setSituacao] = useState([
    {
      label: 'Bloqueadas',
      value: true,
    },
    {
      label: 'Desbloqueadas',
      value: false,
    },
  ]);
  const [loadingButton, setLoadingButton] = useState(false);

  async function listUsers() {
    formRef.current.setData({
      nome: '',
      login: '',
      situacao: '',
    });
    try {
      setLoading(true);

      const { data } = await api_multipague_configuracao.get(`/usuario`);
      const formatted = data.map(val => ({
        ...val,
        login: val.login,
        name: val.nome,
        is_locked: val.senhaFinanceiraBloqueada,
        lock_date: val.dataBloqueioSenha
          ? moment(new Date(val.dataBloqueioSenha)).format('DD/MM/YYYY')
          : '--',
        loading: false,
      }));
      setRowsTable(formatted);
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        theme,
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    listUsers();
  }, []);

  const desbloquearSenha = useCallback(
    async (row, motivo) => {
      try {
        const showLoading = rowsTable.map(val => ({
          ...val,
          loading: val.id === row.id ? true : false,
        }));

        setRowsTable(showLoading);

        const { data } = await api_multipague_configuracao.put(
          `/usuario/senha-financeira/${row.login}/desbloquear?motivo=${motivo}`,
        );

        const hideLoading = rowsTable.map(val => ({
          ...val,
          loading: val.id === row.id ? false : val.loading,
          is_locked: val.id === row.id ? !val.is_locked : val.is_locked,
        }));

        setRowsTable(hideLoading);
      } catch (err) {
        toastComponentError(
          ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
          theme,
        );
      } finally {
        setPopUpState({
          ...popUpState,
          show: false,
        });
      }
    },
    [rowsTable, popUpState],
  );

  const bloquearSenha = useCallback(
    async (row, motivo) => {
      try {
        const showLoading = rowsTable.map(val => ({
          ...val,
          loading: val.id === row.id ? true : false,
        }));

        setRowsTable(showLoading);

        const { data } = await api_multipague_configuracao.put(
          `/usuario/senha-financeira/${row.login}/bloquear?motivo=${motivo}`,
        );

        const hideLoading = rowsTable.map(val => ({
          ...val,
          loading: val.id === row.id ? false : val.loading,
          is_locked: val.id === row.id ? !val.is_locked : val.is_locked,
        }));

        setRowsTable(hideLoading);
      } catch (err) {
        toastComponentError(
          ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
          theme,
        );
      } finally {
        setPopUpState({
          ...popUpState,
          show: false,
        });
      }
    },
    [rowsTable, popUpState],
  );

  const filterUsers = useCallback(async () => {
    try {
      setLoadingButton(true);

      const { data } = await api_multipague_configuracao.get(
        `usuario?nome=${formRef.current.getData().nome}&senhaBloqueada=${
          formRef?.current?.getData()?.situacao
        }&login=${
          formRef?.current?.getData()?.login
            ? formRef?.current?.getData()?.login
            : ''
        }`,
      );

      const formatted = data.map(val => ({
        ...val,
        login: val.login,
        name: val.nome,
        is_locked: val.senhaFinanceiraBloqueada,
        lock_date: val.dataBloqueioSenha
          ? moment(new Date(val.dataBloqueioSenha)).format('DD/MM/YYYY')
          : '--',
        loading: false,
      }));
      setRowsTable(formatted);
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        theme,
      );
    } finally {
      setLoadingButton(false);
    }
  }, [rowsTable]);

  const [popUpState, setPopUpState] = useState({
    title: '',
    text: '',
    onPress: () => console.log(''),
    buttonPressName: 'Bloquear',
    show: false,
  });

  function actionPasswordUser(type, row) {
    if (type === 'lock') {
      setPopUpState({
        title: 'Bloquear',
        text: 'Deseja mesmo bloquear a senha financeira deste usuario?',
        onPress: motivo => bloquearSenha(row, motivo),
        onCancel: () =>
          setPopUpState({
            ...popUpState,
            show: false,
          }),
        buttonPressName: 'Bloquear',
        show: true,
      });
    } else {
      setPopUpState({
        title: 'Desbloquear',
        text: 'Deseja mesmo desbloquear a senha financeira deste usuario?',
        onPress: motivo => desbloquearSenha(row, motivo),
        onCancel: () =>
          setPopUpState({
            ...popUpState,
            show: false,
          }),
        buttonPressName: 'Desbloquear',
        show: true,
      });
    }
  }

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <InputText id="nome" name="nome" label="Nome" variant="outlined" />
        ),
        grid: { xs: 12, sm: 5, md: 4, lg: 4 },
      },
      {
        html: () => (
          <InputText id="login" name="login" label="Login" variant="outlined" />
        ),
        grid: { xs: 12, sm: 5, md: 3, lg: 3 },
      },
      {
        html: () => (
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: '100%' }}
          >
            <InputLabel
              style={{
                backgroundColor: theme.palette.background.paper,
                padding: '0 5px',
              }}
              shrink
              id="demo-simple-select-outlined-label"
            >
              Situação
            </InputLabel>
            <Select id="situacao" name="situacao" itens={situacao} />
          </FormControl>
        ),
        grid: { xs: 12, sm: 5, md: 3, lg: 3 },
      },
    ],
    searchButton: {
      searchButton: () => filterUsers(),
    },
    cleanButton: {
      cleanButton: () => listUsers(),
    },
  };

  return (
    <>
      <Container ref={formRef} onSubmit={filterUsers}>
        <DataGridWrapper
          rows={rowsTable}
          columns={columnsTable}
          pageSize={10}
          loading={loading}
          minHeight="600px"
          backButton
          title={'Senha Financeira'}
          formData={filterInputs}
          crumb={[{ link: '/backoffice/new-backoffice', name: 'Home' }]}
        />
        <AlertPopUp data={popUpState} />
      </Container>
    </>
  );
};
