import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import { Cookies } from 'react-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { MdArrowBack, MdSave } from 'react-icons/md';
import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import { useTheme } from '@material-ui/core/styles';
import { api_multipague_configuracao } from '../../../services/api';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';

const cookies = new Cookies();

export default function CadastroEventos() {
  const history = useHistory();
  const theme = useTheme();
  const cookies = new Cookies();
  const [loadButton, setLoadButton] = useState(false);
  const [selecionarConta, setSelecionarConta] = useState([]);
  const [descricao, setDescricao] = useState('');
  const [agenciaPadrao, setAgenciaPadrao] = useState('');
  const [especiePadrao, setEspeciePadrao] = useState('');
  const [nossoNumero, setNossoNumero] = useState('');
  const [selectContas, setSelectContas] = useState('');
  const [codigo, setCodigo] = useState('');
  const [state, setState] = React.useState({
    checkedA: false,
  });
  const [active, setActive] = React.useState({
    checkedA: false,
  });
  const [valorInput, setValorInput] = useState({});
  localStorage.setItem('valorIdDetalhes', valorInput);

  const CustomSwitch = withStyles({
    switchBase: {
      color: '#6b6b6b',
      '&$checked': {
        color: theme.palette.primary.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const novoCadastro = {
    ativo: active.checkedA,
    nome: descricao,
    agenciaPadrao: agenciaPadrao,
    especiePadrao: especiePadrao,
    origemNossoNumero: nossoNumero,
    id: parseInt(codigo),
    bancoMultipague: state.checkedA,
    tipoContaPadrao: selectContas.toString(),
  };

  useEffect(() => {
    api_multipague_configuracao
      .get(`/tipoConta`)
      .then(response => {
        setSelecionarConta(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleSalvar = value => {
    setLoadButton(true);

    api_multipague_configuracao
      .post(`/banco`, novoCadastro)
      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        toastComponentSuccess('Conta aprovada com sucesso!', theme);
        setTimeout(function () {
          history.push('/backoffice/bancos-lista');
          // localStorage.clear()
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  const handleCodigo = event => {
    setCodigo(event.target.value);
  };
  const handleSenha = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleAtivo = event => {
    setActive({ ...active, [event.target.name]: event.target.checked });
  };

  const handleDescricao = event => {
    setDescricao(event.target.value);
  };
  const handleAgenciaPadrao = event => {
    setAgenciaPadrao(event.target.value);
  };
  const handleEspeciePadrao = event => {
    setEspeciePadrao(event.target.value);
  };
  const handleNossoNumero = event => {
    setNossoNumero(event.target.value);
  };
  return (
    <Wrapper
      backButton
      title="Cadastro"
      crumb={[
        { link: '/backoffice', name: 'Home' },
        {
          link: '/backoffice/associacao-bancos-lista',
          name: 'Associação de Bancos',
        },
        { link: '/backoffice/associacao-bancos-consulta', name: 'Consulta' },
      ]}
    >
      <Box style={{ borderRadius: '20px' }}>
        <div style={{ height: '1rem' }} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={2} lg={2}>
            <TextField
              fullWidth
              label="Código"
              id="outlined-size-small"
              onChange={handleCodigo}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <TextField
              fullWidth
              label="Descrição"
              id="outlined-size-small"
              onChange={handleDescricao}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Agência Padrão"
              id="outlined-size-small"
              onChange={handleAgenciaPadrao}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Espécie Padrão"
              id="outlined-size-small"
              onChange={handleEspeciePadrao}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              options={selecionarConta}
              getOptionLabel={option => option.descricao}
              onChange={(event, option) => {
                setSelectContas(option.id);
              }}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Tipo Conta"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Origem Nosso Número"
              id="outlined-size-small"
              value={nossoNumero}
              onChange={handleNossoNumero}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <FormLabel component="legend">Banco Parceiro</FormLabel>
            <CustomSwitch
              checked={state.checkedA}
              onChange={handleSenha}
              color="primary"
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <FormLabel component="legend">Ativo</FormLabel>
            <CustomSwitch
              checked={active.checkedA}
              onChange={handleAtivo}
              color="primary"
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: '20px' }}>
          <div
            style={{
              margin: '10px',
              width: '100%',
              marginTop: '1rem',
              display: 'flex',
              gap: '1rem',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <LoadingBtn
              variant="contained"
              color="disabled"
              title={'Voltar'}
              startIcon={<MdArrowBack color="background" />}
              onClick={() => history.go(-1)}
            >
              Voltar
            </LoadingBtn>
            <LoadingBtn
              variant="contained"
              color="primary"
              title={'Salvar'}
              isLoading={loadButton}
              startIcon={!loadButton && <MdSave color="background" />}
              onClick={() => {
                handleSalvar();
              }}
            >
              Salvar
            </LoadingBtn>
          </div>
        </Grid>
      </Box>
    </Wrapper>
  );
}
