import moment from 'moment';
import { api_multipague_esteira } from '../../services/api';
import { maskCNPJ, maskCPF } from '../../util/mask';
import FormattedErrorRequest from '../../util/FormattedErrorRequest';
import formattedMoney from '../../util/FormatteMoney';

const formatDadosTransacoes = itemTransacao => {
  const { clienteConta, beneficiarioFavorecido } = itemTransacao;
  const enumTipo = {
    TRANSFERENCIA: 'TED',
    PAGAMENTO_BOLETO: 'BOLETO',
    ARQUIVO_PAGAMENTO: 'ARQUIVO',
  };
  const enumSituacao = {
    APROVADO: 'Concluído',
    PENDENTE: 'Pendente',
    PARCIALMENTE_APROVADO: 'Parciamente Aprovado',
    NEGADO: 'Negado',
    EXPIRADO: 'Expirado',
    CANCELADO: 'Cancelado',
    REJEITADO: 'Rejeitado',
    AGUARDANDO_PROCESSAMENTO_ARQUIVO: 'Aguardando Processamento do Arquivo',
    ARQUIVO_SEM_REGISTRO_SUCESSO: 'Arquivo Sem Registro de Sucesso',
  };
  if (beneficiarioFavorecido != null) {
    if (!beneficiarioFavorecido.hasOwnProperty('agencia')) {
      beneficiarioFavorecido.agencia = '';
      beneficiarioFavorecido.conta = '';
      beneficiarioFavorecido.digitoConta = '';
    }
  }

  const item = {
    id: itemTransacao.id,
    dataCriacao: moment(itemTransacao.dataCadastro).format(
      'DD/MM/YYYY H:mm:ss',
    ),
    nomeTitular: clienteConta?.cliente?.nome,
    cnpjCpfTitular:
      clienteConta?.cliente?.cnpjCpf.lenght === 14
        ? maskCNPJ(clienteConta?.cliente?.cnpjCpf)
        : maskCPF(clienteConta?.cliente?.cnpjCpf),

    nomeBeneficiario: beneficiarioFavorecido ? beneficiarioFavorecido.nome : '',
    cnpjCpfBeneficiario: beneficiarioFavorecido
      ? beneficiarioFavorecido.cnpjCpf.lenght === 14
        ? maskCNPJ(beneficiarioFavorecido.cnpjCpf)
        : maskCPF(beneficiarioFavorecido.cnpjCpf)
      : '',
    conta: beneficiarioFavorecido
      ? `${beneficiarioFavorecido?.conta}-${beneficiarioFavorecido?.digitoConta}`
      : '',
    agencia: beneficiarioFavorecido ? beneficiarioFavorecido?.agencia : '',
    valor: formattedMoney(itemTransacao.valor || 0),
    tipo: enumTipo[itemTransacao.tipoTransacao],
    status: enumSituacao[itemTransacao.situacao],
    situacao: itemTransacao.situacao,
  };
  return item;
};

const codigoClienteConta = 292;
export const itemsPorPaginaTransacao = 5;
export const getTransacoes = async (params, page = 0) => {
  try {
    const { data } = await api_multipague_esteira.get('/transacao-escrow', {
      params: {
        ...params,
        size: itemsPorPaginaTransacao,
        page,
      },
    });
    const { content, totalElements } = data;
    const transacoes = content.map(item => formatDadosTransacoes(item));
    return { transacoes, totalElements };
  } catch (error) {
    const message = FormattedErrorRequest(error);
    console.log('mensagem ', message);
    throw message;
  }
};

export const actionUnit = async (path, id, password) => {
  try {
    return await api_multipague_esteira.put(
      `/transacao-escrow/${id}/${path}`,
      {},
      {
        headers: {
          'x-assinatura-financeira': password,
        },
      },
    );
  } catch (error) {
    const message = FormattedErrorRequest(error);
    throw message;
  }
};

export const actionLote = async (path, ids, password) => {
  try {
    return await api_multipague_esteira.put(
      `/transacao-escrow/${path}/processar-lote`,
      {
        idsTransacoes: ids,
      },
      {
        headers: {
          'x-assinatura-financeira': password,
        },
      },
    );
  } catch (error) {
    const message = FormattedErrorRequest(error);
    throw message;
  }
};
