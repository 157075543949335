import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { api_multipague_configuracao } from '../../../services/api';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@material-ui/icons/Edit';

import { useTheme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import MyDataGrid from '../../../components/DataGrid/DataGridWrapper';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';

export default function AssociacaoBancosConsulta() {
  const history = useHistory();
  const theme = useTheme();

  const columns = [
    { field: 'id', headerName: 'Evento', flex: 1, minWidth: 80 },
    { field: 'evento', headerName: 'Descrição', flex: 3, minWidth: 200 },
    { field: 'banco', headerName: 'Banco', flex: 3, minWidth: 200 },
    {
      field: 'editar',
      headerName: 'Editar',
      type: 'text',
      minWidth: 80,
      flex: 1,
      renderCell: event => {
        return (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              handleEditar(event.row);
            }}
            style={{ borderRadius: '20px' }}
          >
            <EditIcon
              style={{ cursor: 'pointer' }}
              color={theme.palette.primary.main}
              size={20}
            />
          </Button>
        );
      },
    },
  ];

  const [rows, setRows] = useState([]);

  const [loadButton, setLoadButton] = useState(false);
  const [evento, setEvento] = useState('');

  function novoRegistro() {
    history.push('/backoffice/associacao-bancos-cadastro');
  }
  const idBanco = localStorage.getItem('assIdBanco');
  const URLIdBanco = `/eventoBanco?codigoBanco=${idBanco}`;
  const URLEvento = `/eventoBanco?codigoBanco=${idBanco}&codigoEvento=${evento}`;

  function pesquisar(URL) {
    api_multipague_configuracao
      .get(URL)
      .then(response => {
        setLoadButton(false);
        const monitorFilter = response.data;

        const aquiTable = monitorFilter.map(data => ({
          ...data,
          id: data.evento.id,
          evento: data.evento.descricao,
          banco: `${data.banco.id} - ${data.banco.nome}`,
          bancoId: data.banco.id,
          agenciaPadrao: data.banco.agenciaPadrao,
          especiePadrao: data.banco.especiePadrao,
          origemNossoNumero: data.banco.origemNossoNumero,
          tipoConta: data.banco.tipoContaPadrao,
          valorMinimo: data.valorMinimo,
          valorMaximo: data.valorMaximo,
          valorHoraMin: data.horaMinimo,
          valorHoraMax: data.horaMaximo,
          qtdDiasLimitePagamento: data.qtdDiasLimitePagamento,
          assEditIdEventoBanco: data.id,
        }));
        setRows(aquiTable);
      })
      .catch(error => {
        setLoadButton(false);
        setRows([]);
        const toastAlert =
          error.response.status == 404
            ? toastComponentSuccess(error.response.data.mensagem, theme)
            : toastComponentError(error.response.data.mensagem, theme);
      });
  }

  useEffect(() => {
    setLoadButton(true);
    pesquisar(URLIdBanco);
  }, [idBanco]);

  const handleEditar = value => {
    localStorage.setItem('assEditEventoId', value.id);
    localStorage.setItem('assEditEvento', value.evento);
    localStorage.setItem('assEditIdEventoBanco', value.assEditIdEventoBanco);
    localStorage.setItem('assEditBanco', value.banco);
    localStorage.setItem('assEditId', value.bancoId);
    localStorage.setItem('assEditAgenciaPadrao', value.agenciaPadrao);
    localStorage.setItem('assEditEspeciePadrao', value.especiePadrao);
    localStorage.setItem('assEditOrigem', value.origemNossoNumero);
    localStorage.setItem('assEditTipoConta', value.tipoConta);
    localStorage.setItem('assEditVlrMin', value.valorMinimo);
    localStorage.setItem('assEditVlrMax', value.valorMaximo);
    localStorage.setItem('assEditHoraMin', value.valorHoraMin);
    localStorage.setItem('assEditHoraMax', value.valorHoraMax);
    localStorage.setItem('assEditDias', value.qtdDiasLimitePagamento);
    history.push('/backoffice/associacao-bancos-editar');
  };

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <TextField
            fullWidth
            label="Evento"
            value={evento}
            id="outlined-size-small"
            onChange={e => {
              setEvento(e.target.value);
            }}
            variant="outlined"
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      },
    ],
    searchButton: {
      searchButton: () => pesquisar(URLEvento),
    },
    cleanButton: {
      cleanButton: () => {
        pesquisar(URLIdBanco);
        setEvento('');
      },
    },
  };

  return (
    <>
      <MyDataGrid
        crumb={[
          { link: '/backoffice', name: 'Home' },
          {
            link: '/backoffice/associacao-bancos-lista',
            name: 'Associação de Bancos',
          },
        ]}
        formData={filterInputs}
        title="Consulta"
        newRegister={novoRegistro}
        minHeight={'600px'}
        rows={rows}
        columns={columns}
        loading={loadButton}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </>
  );
}
