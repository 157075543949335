import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { AiFillPrinter } from 'react-icons/ai';
import formattedMoney from '../../util/FormatteMoney/index';
import Button from '../../components/Buttons/ConfirmButton';

const useStyles = makeStyles(theme => ({
  root: {
    height: 280,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonOff: {
    display: 'none',
  },
  comprovante: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  grey: {
    color: 'grey',
  },
  icons: {
    marginTop: '3px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  alinhamento: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  alinhar: {
    float: 'left',
  },
}));

export default function PdfMonitor() {
  const classes = useStyles();
  const theme = useTheme();

  function printDiv() {
    var css = '@page { size: landscape; }',
      head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    style.type = 'text/css';
    style.media = 'print';

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
    // document.getElementById('printButton').className="buttonOff"
    document.getElementById('printButton').classList.add('buttonOff');
    document.getElementById('printButton').style.display = 'none';
    window.print();
    document.getElementById('printButton').style.display = 'inline';
  }

  const rows = JSON.parse(localStorage.getItem('pdfMonitor'));
  console.log('Testando pdf: ', rows);

  const newRows = rows.map(item => (
    <div className={classes.alinhamento}>
      <Grid
        container
        spacing={3}
        style={{ marginBottom: '10px', marginTop: '10px' }}
      >
        <Grid item xs={12} md={2}>
          {item.cliente.dataAberturaConta
            ? moment(new Date(item.cliente.dataAberturaConta)).format(
                'DD/MM/YYYY',
              )
            : '-'}
        </Grid>
        <Grid item xs={12} md={3}>
          {item.cliente.nome}
        </Grid>
        <Grid item xs={12} md={3}>
          {item.tipoControle}
        </Grid>
        <Grid item xs={12} md={2}>
          {item.cliente.dataAprovacao
            ? moment(new Date(item.cliente.dataAprovacao)).format('DD/MM/YYYY')
            : '-'}
        </Grid>
        <Grid item xs={12} md={2}>
          {item.cliente.situacao}
        </Grid>
      </Grid>
      <Divider />
    </div>
  ));

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <div id="printableArea">
          <img alt="" height="80" src={theme.config.menuLogo} />
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              variant="contained"
              placement={"top"}
              color="primary"
              id="printButton"
              title={"Imprimir"}
              startIcon={<AiFillPrinter color="background" />}
              onClick={printDiv}
            >
              Imprimir
            </Button>
            <br />
          </div>
          <div
            className={classes.comprovante}
            style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}
          >
            <Typography variant="h5" component="h5">
              Monitor Backoffice
            </Typography>
          </div>
          <div className={classes.alinhamento}>
            <div>
              <Typography variant="h6" component="h6">
                Abertura
              </Typography>
            </div>
            <div>
              <Typography variant="h6" component="h6">
                Nome
              </Typography>
            </div>
            <div>
              <Typography variant="h6" component="h6">
                Controle
              </Typography>
            </div>
            <div>
              <Typography variant="h6" component="h6">
                Aprovação
              </Typography>
            </div>
            <div>
              <Typography variant="h6" component="h6">
                Situação
              </Typography>
            </div>
          </div>
          {newRows}
        </div>
      </Container>
    </React.Fragment>
  );
}
