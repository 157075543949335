import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import LockIcon from '@material-ui/icons/Lock';
import { InputPassword } from './components/InputPassword';
import { api_multipague_cadastrar_senha } from '../../services/api';

import Loading from '../../components/Loading';
import ContainerComponent from '../../components/Container';
import Button from '../../components/Buttons/ConfirmButton';

import {
  Container,
  Main,
  ContainerAccessPassword,
  Header,
  Title,
  ContainerIcon,
  InputsContainer,
  Label,
  Form,
  ContainerButtons,
  Copyright,
} from './styles';
import { useHistory } from 'react-router-dom';

export function RegistrarSenha() {
  let params = new URLSearchParams(document.location.search.substring(1));
  let tokenUser = params.get('token');
  var { LOGIN, PERFIL, jti } = jwt_decode(tokenUser);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
  const regexNumeric = /^(?=.*[0-9])[\d]{6,6}$/;
  const history = useHistory();
  const root = {
    path: [
      {
        name: 'Início',
        caminho: '/',
      },
    ],
    pagAtual: 'Usuários',
  };

  function renderSenhaAcesso() {
    const [password, setPassword] = useState({
      pws: '',
      conf_pwf: '',
      isMatch_pwf: null,
      finc_pws: '',
      finc_conf_pwf: '',
      isMatch_finc_pwf: null,
    });

    async function sendPassword() {
      try {
        setLoadingScreen(true);
        console.log(password);
        const md5 = require('md5');
        const { data } = await api_multipague_cadastrar_senha.post(
          `usuario/senha/definir?token=${tokenUser}`,
          {
            confirmacaoSenhaAcesso: password.conf_pwf,
            confirmacaoSenhaFinanceira: md5(password.finc_conf_pwf),
            senhaAcesso: password.pws,
            senhaFinanceira: md5(password.finc_pws),
          },
        );
        toast.success(` Senha cadastrada com sucesso`);
        history.push('/backoffice');
      } catch (err) {
        toast.error(
          ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        );
        console.log(err);
      } finally {
        setLoadingScreen(false);
      }
    }

    function validarSenhas() {
      debugger;
      if (!password.isMatch_pwf) {
        toast.error(
          `Senha de acesso: Obrigatório no mínimo 6 caracteres, sendo 1 letra maiúscula, 1 caractere especial e 1 número.
          `,
        );
        return;
      }

      if (password.pws != password.conf_pwf) {
        toast.error(` Senha Acesso nao batem`);
        return;
      }

      if (PERFIL != 'backoffice-empresa') {
        if (!password.isMatch_finc_pwf) {
          toast.error(
            ` Senha financeira precisa seguir os seguintes parametros: 6 números`,
          );
          return;
        }

        if (password.finc_conf_pwf != password.finc_pws) {
          toast.error(` Senha financeira nao batem`);
          return;
        }
      }

      sendPassword();
    }

    return (
      <ContainerAccessPassword>
        <Header>
          <ContainerIcon>
            <LockIcon color="primary" />
          </ContainerIcon>
          <Title>
            <p>Registre suas senhas</p>
          </Title>
        </Header>
        <Form>
          <InputsContainer>
            <Label>
              <p>Digite a sua senha de acesso</p>
            </Label>
            <InputPassword
              value={password.pws}
              onChange={val =>
                setPassword({
                  ...password,
                  pws: val,
                  isMatch_pwf: val.match(regexPassword),
                })
              }
              label="Senha de Acesso"
              labelWidth={100}
            />
          </InputsContainer>
          <InputsContainer>
            <Label>
              <p>Confirme sua nova senha de acesso</p>
            </Label>
            <InputPassword
              value={password.conf_pwf}
              onChange={val =>
                setPassword({
                  ...password,
                  conf_pwf: val,
                })
              }
              label="Confirmação Senha de Acesso"
              labelWidth={180}
            />
          </InputsContainer>
          {PERFIL != 'backoffice-empresa' && (
            <>
              <InputsContainer>
                <Label>
                  <p>Digite a sua senha financeira</p>
                </Label>
                <InputPassword
                  value={password.finc_pws}
                  onChange={val =>
                    setPassword({
                      ...password,
                      finc_pws: val.replace(/[^\d]/g, ''),
                      isMatch_finc_pwf: val.match(regexNumeric),
                    })
                  }
                  label="Senha Financeira"
                  labelWidth={100}
                />
              </InputsContainer>
              <InputsContainer>
                <Label>
                  <p>Confirme a sua senha financeira</p>
                </Label>
                <InputPassword
                  value={password.finc_conf_pwf}
                  onChange={val =>
                    setPassword({
                      ...password,
                      finc_conf_pwf: val.replace(/[^\d]/g, ''),
                    })
                  }
                  label="Confirmação Senha Financeira"
                  labelWidth={180}
                />
              </InputsContainer>
            </>
          )}
        </Form>
        <ContainerButtons>
          <Button
            color="primary"
            variant="contained"
            disabled={false}
            width={100}
            onClick={() => validarSenhas()}
          >
            Confirmar
          </Button>
        </ContainerButtons>
      </ContainerAccessPassword>
    );
  }

  function renderSenhaFinanceira() {
    const [password, setPassword] = useState({
      code: '',
      pws: '',
      conf_pwf: '',
    });

    return (
      <ContainerAccessPassword>
        <Header>
          <ContainerIcon>
            <LockIcon color="primary" />
          </ContainerIcon>
          <Title>
            <p>Registre sua senha financeira</p>
          </Title>
        </Header>
        <Form>
          <InputsContainer>
            <Label>
              <p>Digite o código enviado para seu e-mail</p>
            </Label>
            <InputPassword
              value={password.code}
              onChange={val =>
                setPassword({
                  ...val,
                  code: val,
                })
              }
              label="teste"
            />
          </InputsContainer>
          <InputsContainer>
            <Label>
              <p>Digite a sua senha financeira</p>
            </Label>
            <InputPassword
              value={password.pws}
              onChange={val =>
                setPassword({
                  ...val,
                  conf_pwf: val,
                })
              }
              label="teste"
            />
          </InputsContainer>
          <InputsContainer>
            <Label>
              <p>Confirme a sua senha financeira</p>
            </Label>
            <InputPassword
              value={password.pws}
              onChange={val =>
                setPassword({
                  ...val,
                  code: val,
                })
              }
              label="teste"
            />
          </InputsContainer>
        </Form>
        <ContainerButtons>
          <Button
            color="primary"
            variant="contained"
            disabled={false}
            width={100}
          >
            Confirmar
          </Button>
        </ContainerButtons>
      </ContainerAccessPassword>
    );
  }

  return (
    <>
      <Loading show={loadingScreen} />
      <ContainerComponent noMenu>
        <Container>
          <Main>
            {renderSenhaAcesso()}
            <Copyright>
              <p>Todos os Direitos Reservados.Grupo Multiplica 2021.</p>
            </Copyright>
          </Main>
        </Container>
      </ContainerComponent>
    </>
  );
}
