import React, { useCallback, useEffect, useState } from 'react';
import { getSaldos } from './service';
import { Chip, Paper, Grid, TextField, Box } from '@material-ui/core';
import { maskCNPJ } from '../../util/mask';
import formattedMoney from '../../util/FormatteMoney';
import DataGridWrapper from '../../components/DataGrid/DataGridWrapper';

const PainelSaldos = () => {
  const initialForm = {
    cnpjCpf: '',
    nome: '',
  };

  const columns = [
    { field: 'cnpj', headerName: 'CNPJ', width: 200 },

    { field: 'razaoSocial', headerName: 'Razão Social', width: 400 },
    {
      field: 'conta',
      headerName: 'Conta',
      type: 'text',
      width: 300,
      renderCell: event => {
        return (
          <div>
            {event.row.conta}
            {event.row.error && (
              <Chip
                size="small"
                variant="outlined"
                label={event.row.error}
                color="secondary"
              />
            )}
          </div>
        );
      },
    },

    { field: 'tipoControle', headerName: 'Tipo Controle', width: 200 },
    { field: 'dataAbertura', headerName: 'Data Abertura', width: 200 },
    { field: 'dataUltimaMov', headerName: 'Data Última Mov.', width: 200 },
    {
      field: 'saldo',
      headerName: 'Saldo',
      width: 200,
      renderCell: event => {
        return (
          <>
            <span style={{ display: 'none' }}>{event.row.saldo}</span>
            {formattedMoney(event.row.saldo)}
          </>
        );
      },
    },
  ];

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState(initialForm);
  const [rowCount, setRowCount] = useState(0);
  const [rowsState, setRowsState] = React.useState(0);

  const fetchPainelSaldo = useCallback(async (params = {}, page = 0) => {
    setRowsState(page);
    setLoading(true);
    const data = await getSaldos({ ...params, page });
    setRows(data.content);
    setRowCount(data.total);
    setLoading(false);
  }, []);

  const handleInput = e => {
    const value =
      e.target.name === 'cnpjCpf' ? maskCNPJ(e.target.value) : e.target.value;
    setForm(old => ({ ...old, [e.target.name]: value }));
  };

  useEffect(() => {
    fetchPainelSaldo();
  }, [fetchPainelSaldo]);

  const filterPainelSaldo = page => {
    if (form.cnpjCpf) {
      form.cnpjCpf = form.cnpjCpf.replace(/\D/g, '');
    }
    fetchPainelSaldo(form, page);
  };

  const filterInputs = {
    inputsData: [
      {
        html: () => (
          <TextField
            fullWidth
            label="Razão Social"
            value={form.nome}
            variant="outlined"
            size="small"
            name="nome"
            onChange={handleInput}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 5 },
      },
      {
        html: () => (
          <TextField
            fullWidth
            label="CNPJ"
            value={form.cnpjCpf}
            variant="outlined"
            size="small"
            name="cnpjCpf"
            onChange={handleInput}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ),
        grid: { xs: 12, sm: 5 },
      },
    ],
    searchButton: {
      searchButton: () => filterPainelSaldo(0),
    },
    cleanButton: {
      cleanButton: () => fetchPainelSaldo() && setForm(initialForm),
    },
  };

  return (
    <DataGridWrapper
      backButton
      title={'Painel de Saldos'}
      crumb={[{ link: '/backoffice/new-backoffice', name: 'Home' }]}
      rows={rows}
      formData={filterInputs}
      columns={columns}
      loading={loading}
      pageSize={10}
      minHeight="600px"
      pagination
      page={rowsState}
      paginationMode="server"
      rowCount={rowCount}
      onPageChange={newPage => {
        filterPainelSaldo(newPage);
      }}
    />
  );
};

export default PainelSaldos;
