import TextField from '@material-ui/core/TextField';
import React from 'react';

const DateComponent = ({
  formatoData = 'dd/MM/yyyy',
  id,
  label,
  dataInicial = Date.now(),
  callback,
  dataMinima,
  ...rest
}) => {
  const handleDateChange = date => {
    console.log(date.target.value);
    callback(date.target.value);
  };

  return (
    <TextField
      fullWidth
      id={id}
      label={label}
      type="date"
      size="small"
      variant="outlined"
      defaultValue={dataInicial}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={handleDateChange}
      {...rest}
    />
  );
};

export default DateComponent;
