import React from 'react';
import { DataGrid } from '@material-ui/data-grid';

import { ptBR } from './../locale';

import { Container, useStylesDatagrid } from './style';
import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  alpha,
  useTheme,
} from '@material-ui/core';
import Wrapper from '../../Wrapper';
import { MdSearch, MdCleaningServices } from 'react-icons/md';

import { ExcelGrid } from './ExcelGrid';

const MyDataGrid = ({
  URL,
  headerComponent,
  rows,
  columns,
  pageSize,
  backButton,
  loading,
  minHeight,
  title,
  selection,
  newRegister,
  children,
  PDFCSV,
  formData = {
    inputsData: [],
    searchButton: { ...rest, searchButton: Function },
    cleanButton: { ...rest, cleanButton: Function },
  },
  crumb,
  handlePDFCSV = {
    downloadPDF: () => {},
    downloadExcel: () => {},
    rowsExcel: [],
    ExcelColumn: [],
    carregaExcel: false,
    carregaIconExcel: false,
    title: '',
  },
  ...rest
}) => {
  const classes = useStylesDatagrid();

  return (
    <Wrapper
      newRegister={newRegister}
      title={title}
      crumb={crumb.map(item => item)}
      backButton={backButton}
    >
      <Grid className={classes.formBackground} container spacing={2}>
        {formData.inputsData?.map(item => (
          <Grid className={classes.root} item {...item.grid}>
            {item.html &&
              item.html({
                style: { height: '50px', borderRadius: '20px' },
                ...item,
              })}
          </Grid>
        ))}
        {!!formData.inputsData.length > 0 && (
          <Grid item spacing={2} className={classes.gridLoadingButton}>
            <Tooltip title="Buscar">
              <IconButton
                variant="contained"
                color="primary"
                className={classes.loadingButton}
                isLoading={formData.searchButton.loadingButtonSearch}
                onClick={formData.searchButton.searchButton}
              >
                <MdSearch
                  color={useTheme().palette.getContrastText(
                    alpha(
                      useTheme().palette.appBar[useTheme().palette.type].bgMenu,
                      0.4,
                    ),
                  )}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Limpar">
              <IconButton
                variant="contained"
                color="primary"
                className={classes.loadingButton}
                onClick={formData.cleanButton.cleanButton}
              >
                <MdCleaningServices
                  color={useTheme().palette.getContrastText(
                    alpha(
                      useTheme().palette.appBar[useTheme().palette.type].bgMenu,
                      0.4,
                    ),
                  )}
                />
              </IconButton>
            </Tooltip>
            {PDFCSV && <ExcelGrid handlePDFCSV={handlePDFCSV} />}
          </Grid>
        )}
      </Grid>
      <Container>
        <Paper
          className={classes.dataGridPaper}
          elevation={0}
          data-testid="container-datagrid"
        >
          {headerComponent && (
            <div className="headerButtons">
              {React.createElement(headerComponent)}
            </div>
          )}

          <div style={{ minHeight }}>
            <DataGrid
              disableColumnMenu
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              loading={loading}
              style={{ minHeight, boxShadow: 'none', position: 'relative' }}
              {...rest}
              classes={{
                headerCell: classes.headerBold,
                row: classes.rowText,
              }}
              headerHeight={52}
              rowHeight={48}
              localeText={ptBR}
            />
          </div>
        </Paper>
      </Container>
    </Wrapper>
  );
};

export default MyDataGrid;
