import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { api_multipague_configuracao } from '../../services/api';
import validarCpf from '../../util/vlidarCpfCnpj';
import { maskCpfCnpj } from '../../util/mask';
import getValidationErros from '../../util/FormattedError';
import GlobalContainer from '../../components/Container';
import InputText from '../../components/InputText';
import CPF from '../../components/CPF';
import DDD from '../../components/DDD';
import Telefone from '../../components/Telefone';
import Select from '../../components/Select';
import Button from '../../components/Buttons/ConfirmButton';
import Loading from '../../components/Loading';
import {
  Container,
  Subcontainer,
  ContainerButtons,
  ContainerInputs,
  Title,
  Checks,
} from './styles';
import { useHistory } from 'react-router-dom';
import Wrapper from '../../components/Wrapper';
import { Box, useTheme } from '@material-ui/core';
import { toastComponentError, toastComponentSuccess } from '../../components/Toast';

export const Users = () => {
  const history = useHistory();
  const editingInfo = JSON.parse(localStorage.getItem('edicao_usuario'));
  const formRef = useRef(null);
  useEffect(() => {
    const verifyMaster = localStorage.getItem('roles');
    const roles = verifyMaster.includes('usuario_master');

    if (!roles) {
      history.push('/backoffice');
    }
  }, []);
  const root = {
    path: [
      {
        name: 'Home',
        caminho: '/backoffice',
      },
    ],
    pagAtual: 'Novo Usúario',
  };
  // BackOffice Empresa ou BackOffice Multiplica
  const [situacao, setSituacao] = useState([
    {
      label: 'BackOffice Consulta',
      value: 'backoffice-empresa',
    },
    {
      label: 'BackOffice Master',
      value: 'backoffice-multiplica',
    },
  ]);
  const [acessoBackOfficer, setAcessoBackOfficer] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  useEffect(() => {
    if (editingInfo) {
      console.log(editingInfo);
      formRef.current?.setData({
        nome: editingInfo.nome,
        usuario: editingInfo.login,
        email: editingInfo.email,
        ddd: editingInfo.ddd,
        telefone: editingInfo.telefone
      });
      setAcessoBackOfficer(editingInfo.backoffice);
    }
  }, []);

  const cadastrarUsuario = async body => {
    try {
      setLoadingScreen(true);
      const schmeCadastro = Yup.object().shape({
        nome: Yup.string().required('Campo obrigatorio'),
        usuario: Yup.string().required('Campo obrigatorio'),
        ddd: Yup.string().required('Campo obrigatorio'),
        telefone: Yup.string().required('Campo obrigatorio'),
        email: Yup.string().required('Campo obrigatorio'),
        perfil: Yup.string().required('Campo obrigatorio'),
      });

      await schmeCadastro.validate(body, {
        abortEarly: false,
      });

      if (!validarCpf(body.usuario)) {
        formRef.current.setFieldError('usuario', 'CPF inválido');
        return;
      }

      const bodyResquest = {
        ...body,
        atributos: {
          profileBackOffice: acessoBackOfficer,
        },
        confirmacaoSenha: null,
        confirmacaoSenhaFinanceira: null,
        ativo: true,
        idCliente: editingInfo?.id ?? null,
        master: true,
        senha: null,
        senhaFinanceira: null,
        usuario: body.usuario.replace(/[^\d]/g, ''),
      };

      if (!!editingInfo?.id) {
        const updateBody = {
          email: body.email,
          nome: body.nome,
          ddd: body.ddd,
          telefone: body.telefone,
          sobrenome: '',
        };

        const { data } = await api_multipague_configuracao.put(
          `usuario/${editingInfo.id}`,
          updateBody,
        );
      } else {
        const { data } = await api_multipague_configuracao.post(
          'usuario',
          bodyResquest,
        );
      }

      localStorage.removeItem('edicao_usuario');
      history.push('/backoffice/userlist');
      toastComponentSuccess('Usuário cadastrado com sucesso', useTheme());
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationErros(err);
        formRef.current?.setErrors(erros);
      } else {
        toastComponentError(
          ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`, theme
        );
        localStorage.removeItem('edicao_usuario');
        console.log(err);
      }
    } finally {
      setLoadingScreen(false);
    }
  };

  return (
    <Wrapper
      backButton
      title="Cadastro de Usuário"
      crumb={[{ link: '/backoffice', name: 'Home' }]}
    >
      <Box>
        <Loading show={loadingScreen} />
        <Container ref={formRef} onSubmit={cadastrarUsuario}>
          <Subcontainer>
            <ContainerInputs>
              <InputText
                id="nome"
                name="nome"
                label="Nome Completo"
                variant="outlined"
              />
              <CPF name="usuario" id="usuario" variant="outlined" label="CPF" />
              <DDD
                id="ddd"
                name="ddd"
                label="DDD"
                variant="outlined"
                inputProps={{ maxLength: 2 }}
              />
              <Telefone
                id="telefone"
                name="telefone"
                label="Telefone"
                variant="outlined"
                type="text"
                inputProps={{ maxLength: 9, inputMode: 'numeric' }}
              />
              <InputText
                id="email"
                name="email"
                label="E-mail"
                variant="outlined"
              />
              <Select
                id="perfil"
                name="perfil"
                label="Perfil"
                itens={situacao}
              />
              {/* <InputText
              id="senha_acesso"
              name="senha_acesso"
              label="Senha de Acesso"
              variant="outlined"
            />
            <InputText
              id="conf_senha_acesso"
              name="conf_senha_acesso"
              label="Confirme a Senha de Acesso"
              variant="outlined"
            />
            <InputText
              id="senha_financeira"
              name="senha_financeira"
              label="Senha Financeira"
              variant="outlined"
            />
            <InputText
              id="conf_senha_financeira"
              name="conf_senha_financeira"
              label="Confirme a Senha Financeira"
              variant="outlined"
            /> */}
            </ContainerInputs>
            <Checks>
              <FormControlLabel
                control={
                  <Switch
                    onChange={event => setAcessoBackOfficer(!acessoBackOfficer)}
                    checked={acessoBackOfficer}
                    color="primary"
                  />
                }
                label="Acesso ao BackOffice"
              />
            </Checks>
            <ContainerButtons>
              <Button
                color="primary"
                variant="contained"
                disabled={false}
                width={100}
                type="button"
                onClick={() => history.goBack()}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={false}
                width={100}
                type="submit"
              >
                Salvar
              </Button>
            </ContainerButtons>
          </Subcontainer>
        </Container>
      </Box>
    </Wrapper>
  );
};
