import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Switch from '@material-ui/core/Switch';
import Wrapper from '../../components/Wrapper';
import TopBar from '../../components/TopBar';
import Container from '@material-ui/core/Container';
import FormGroup from '@material-ui/core/FormGroup';
import SaveIcon from '@material-ui/icons/Save';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '../../components/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import AddBoxIcon from '@material-ui/icons/AddBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {
  api_multipague_configuracao,
  api_multipague_conta,
} from '../../services/api';
import { Cookies } from 'react-cookie';
import LoadingBtn from '../../components/Buttons/ConfirmButton';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
// import { showLoading, hideLoading } from '../../store/modules/loading/actions'
import { ToastContainer, toast } from 'react-toastify';

const cookies = new Cookies();
const tknAccess = cookies.get('tkn-access');

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  bread: {
    paddingTop: theme.spacing(2),
    marginLeft: '25px',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#fff',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: '300px',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  buttonsCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonsAdd: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  fab: {
    margin: theme.spacing.unit,
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  grid: {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    alignItems: 'center',
    marginLeft: '10px',
    width: '100px',
  },
  inputBloq: {
    display: 'none',
  },
  inputFree: {
    display: 'block',
  },
  alerta: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function MeuPerfil() {
  const condicaoBloqueado = situacao === 'BLOQUEADA' ? true : false;
  const [state, setState] = React.useState({
    checkedA: false,
  });

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleChangeSelectContas = event => {
    setSelectContas(event.target.value);
  };

  const classes = useStyles();

  const [tipoControle, setTipoControle] = useState('');
  const [eSolidaria, setESolidaria] = useState('');

  const [clienteCorrentista2Cpf, setClienteCorrentista2Cpf] = useState('');

  const [motivoBloqueio, setMotivoBloqueio] = useState('');

  const valorIdDetalhes = localStorage.getItem('valorIdDetalhes');
  const valorIdBloqueio = localStorage.getItem('valorIdBloqueio');
  const [loadButton, setLoadButton] = useState(false);
  const [situacao, setSituacao] = useState('');
  const [accountInformation, setAccountInformation] = useState();
  const [controlePerfil, setControlePerfil] = useState('');

  useState(false);
  const [showNovoCorrentista, setShowNovoCorrentista] = useState(false);

  const [currentInformation, setCurrentInformation] = useState({
    clienteContas: [],
    clienteCorrentistas: [],
    cnpjCpf: '',
    id: '',
    nome: '',
    dataAberturaConta: '',
    usuarioAberturaConta: '',
    situacao: '',
    pessoa: {
      id: '',
      nomeFantasia: '',
      documentoMunicipal: '',
      documentoEstadual: '',
      pessoasEndereco: [],
      pessoaContatos: [
        {
          contatoPrincipal: '',
          dataCadastro: '',
          ddd: '',
          ddi: '',
          email: '',
          id: '',
          nomeContato: '',
          paginaWeb: '',
          redeSocial: '',
          telefone: '',
          tipoTelefone: '',
          usuarioCadastro: '',
        },
      ],
      razaoSocial: '',
    },
  });

  const camposObrigatorios =
    currentInformation.cnpjCpf != '' &&
    currentInformation.dataAberturaConta != '' &&
    currentInformation.id != '' &&
    currentInformation.nome != '' &&
    currentInformation.pessoa != '';

  console.log(
    '🚀 ~ file: index.js:241 ~ MeuPerfil ~ camposObrigatorios:',
    camposObrigatorios,
  );

  useEffect(() => {
    api_multipague_configuracao
      .get('/banco/parceiros')
      .then(response => {
        console.log('Select do cadastro ', response.data);
        const selectFilter = response.data;
        const aquiSelect = selectFilter.map(key => (
          <MenuItem key={key} value={key.id}>
            {key.nome}
          </MenuItem>
        ));
        setSelectContas(aquiSelect);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const AuthStr = 'Bearer '.concat(tknAccess);
  const URL = `/conta/${valorIdDetalhes}/cliente`;

  useEffect(() => {
    api_multipague_conta
      .get(URL, {
        headers: {
          Authorization: AuthStr,
        },
      })
      .then(response => {
        console.log(response.data);
        const formatted = {
          cnpjCpf: response.data.cnpjCpf,
          id: response.data.id,
          nome: response.data.nome,
          dataAberturaConta: response.data.dataAberturaConta,
          usuarioAberturaConta: response.data.usuarioAberturaConta,
          clienteContas: response.data?.clienteContas?.map(account => ({
            assinaturaSolidaria: account.assinaturaSolidaria,
            agencia: account.agencia,
            id: account.id,
            conta: account.conta,
            digitoConta: account.digitoConta,
            banco: account?.banco.nome,
            tipoControle:
              account.tipoControle == null
                ? 'Nenhum tipo de controle'
                : account.tipoControle,
          })),
          clienteCorrentistas: response.data?.clienteCorrentistas?.map(
            clients => ({
              contato: clients.contato,
              cpf: clients.cpf,
              dataCadastro: clients.dataCadastro,
              id: clients.id,
              nome: clients.nome,
              rg: clients.rg,
            }),
          ),
          pessoa: {
            pessoasEndereco: response.data.pessoa.pessoasEndereco.map(
              address => ({
                bairro: address.bairro,
                cep: address.cep,
                cidade: address.cidade,
                complemento: address.complemento,
                dataCadastro: address.dataCadastro,
                endereco: address.endereco,
                enderecoCorrespondencia: address.enderecoCorrespondencia,
                enderecoNum: address.enderecoNum,
                id: address.id,
                tipoEndereco: address.tipoEndereco,
                tipoResidencia: address.tipoResidencia,
                uf: address.uf,
                pais: '',
              }),
            ),
            pessoaContatos: response.data.pessoa.pessoaContatos.map(
              contact => ({
                contatoPrincipal: contact.contatoPrincipal,
                ddd: contact.ddd,
                email: contact.email,
                id: contact.id,
                nomeContato: contact.nomeContato,
                paginaWeb: contact.paginaWeb,
                redeSocial: contact.redeSocial,
                telefone: contact.telefone,
              }),
            ),
            id: response.data?.pessoa?.id,
            razaoSocial: response.data?.pessoa?.razaoSocial,
            nomeFantasia: response.data?.pessoa?.nomeFantasia,
            documentoMunicipal: response.data?.pessoa?.documentoMunicipal,
            documentoEstadual: response.data?.pessoa?.documentoEstadual,
          },
          situacao: response.data?.situacao,
        };

        console.log('formatted', formatted);

        setCurrentInformation(formatted);
        setSituacao(formatted.situacao);

        setAccountInformation(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  function confirmBlock() {
    setLoadButton(true);
    const dataBloq = {
      motivo: motivoBloqueio,
    };
    const URL = `/cliente/${valorIdBloqueio}/bloqueio?&observacao=${motivoBloqueio}`;
    api_multipague_conta
      .post(URL, JSON.stringify(dataBloq), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        setLoadButton(false);
        toast.success('Conta bloqueada com sucesso!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          window.location.href = '/backoffice/pendencias-abertura';
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          // alert(error.response.data.mensagem)
          toast.error(error.response.data.mensagem, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoadButton(false);
        }
      });
  }

  function confirmDesblock() {
    setLoadButton(true);
    const dataBloq = {
      motivo: motivoBloqueio,
    };
    const URL = `/cliente/${valorIdBloqueio}/desbloqueio?&observacao=${motivoBloqueio}`;
    api_multipague_conta
      .post(URL, JSON.stringify(dataBloq), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        setLoadButton(false);
        toast.success('Conta desbloqueada com sucesso!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          window.location.href = '/backoffice/pendencias-abertura';
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          // alert(error.response.data.mensagem)
          toast.error(error.response.data.mensagem, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoadButton(false);
        }
      });
  }

  useEffect(() => {
    console.log(currentInformation);
    setESolidaria(currentInformation.clienteContas[0]?.assinaturaSolidaria);
    setTipoControle(currentInformation.clienteContas[0]?.tipoControle);
  }, [currentInformation]);

  async function salvarEdicao() {
    
    setLoadButton(true);
    const AuthStr = 'Bearer '.concat(tknAccess);
    const URL = `/cliente`;

    const body = {
      ...currentInformation,
      clienteContas: currentInformation.clienteContas.map(account => ({
        assinaturaSolidaria: account.assinaturaSolidaria,
        id: account.id,
        tipoControle: account.tipoControle,
      })),
    };

    api_multipague_conta
      .put(URL, body, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: AuthStr,
        },
      })
      .then(function (response) {
        setLoadButton(false);
        console.log(response);

        toast.success('Dados salvos com sucesso!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          window.location.href = '/backoffice/pendencias-abertura';
        }, 5000);
      })
      .catch(function (error) {
        console.log('Errou Aqui!');
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          toast.error(error.response.data.mensagem, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  }

  return (
    <Wrapper
      backButton
      title="Detalhes do cliente"
      crumb={[{ link: '/backoffice', name: 'Home' }]}
    >
      <Container maxWidth="lg" className={classes.container}>
        <React.Fragment>
          <Box>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginBottom: '20px' }}
                >
                  Dados da Pessoa Jurídica
                </Typography>
              </div>
              <div>
                {situacao === 'BLOQUEADA' ? (
                  <FormGroup row>
                    <LoadingBtn
                      type="submit"
                      style={{
                        float: 'right',
                        textTransform: 'none',
                        margin: 'auto',
                        width: '200px',
                      }}
                      variant="contained"
                      color="primary"
                      isLoading={loadButton}
                      startIcon={!loadButton && <LockOpenIcon />}
                      className={classes.button}
                      onClick={confirmDesblock}
                    >
                      Desbloquear Conta
                    </LoadingBtn>
                  </FormGroup>
                ) : (
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={state.checkedA}
                          onChange={handleChange}
                          name="checkedA"
                        />
                      }
                      label="Bloquear Conta"
                    />
                  </FormGroup>
                )}
              </div>
            </div>
            {clienteCorrentista2Cpf != '' ? (
              <Grid container spacing={3} style={{ marginBottom: '40px' }}>
                <Grid item xs={12} sm={12}>
                  <Alert severity="info">Esta é uma Assinatura Conjunta</Alert>
                </Grid>
              </Grid>
            ) : (
              ''
            )}

            <TextField
              fullWidth
              label="Motivo do bloqueio"
              className={
                state.checkedA === false ? classes.inputBloq : classes.inputFree
              }
              multiline
              rows={4}
              variant="outlined"
              placeholder=""
              onChange={e => {
                setMotivoBloqueio(e.target.value);
              }}
            />

            <Grid
              container
              spacing={3}
              className={
                state.checkedA === false ? classes.inputBloq : classes.inputFree
              }
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ textAlign: 'center', marginBottom: '20px' }}
              >
                <br />
                <LoadingBtn
                  type="submit"
                  style={{
                    textTransform: 'none',
                    margin: 'auto',
                    width: 'auto',
                  }}
                  isLoading={loadButton}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={confirmBlock}
                >
                  Confirmar Bloqueio
                </LoadingBtn>
              </Grid>
              <ToastContainer />
            </Grid>

            {situacao === 'BLOQUEADA' ? (
              <Grid container spacing={3} style={{ marginBottom: '40px' }}>
                <Grid item xs={12} sm={12}>
                  <Alert severity="error">Conta BLOQUEADA!</Alert>
                </Grid>
              </Grid>
            ) : (
              <div></div>
            )}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  // required
                  id="cnpjCpf"
                  name="cnpjCpf"
                  label="CNPJ"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.cnpjCpf}
                  onChange={e => {
                    setCurrentInformation({
                      ...currentInformation,
                      cnpjCpf: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="razaoSocial"
                  name="razaoSocial"
                  label="Razão Social"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa.razaoSocial}
                  onChange={e => {
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        razaoSocial: e.target.value,
                      },
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={6} sm={12}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="nomeFantasia"
                  name="nomeFantasia"
                  label="Nome Fantasia"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa.nomeFantasia}
                  onChange={e => {
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        nomeFantasia: e.target.value,
                      },
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={6} sm={12}>
                <TextField
                  variant="outlined"
                  size="small"
                  id="inscEstadual"
                  name="inscEstadual"
                  label="Inscrição Estadual"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa.documentoEstadual}
                  onChange={e => {
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        documentoEstadual: e.target.value,
                      },
                    });
                  }}
                  fullWidth
                  autoComplete="shipping address-line2"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="inscMunicipal"
                  name="inscMunicipal"
                  label="Inscrição Municipal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa.documentoMunicipal}
                  onChange={e => {
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        documentoMunicipal: e.target.value,
                      },
                    });
                  }}
                  fullWidth
                  autoComplete="shipping address-level2"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  id="dataAbertura"
                  name="dataAbertura"
                  label="Data de Abertura - Ex: dd/mm/aaaa"
                  value={
                    currentInformation.dataAberturaConta == null
                      ? ''
                      : moment(
                          new Date(currentInformation.dataAberturaConta),
                        ).format('DD/MM/YYYY')
                  }
                  onChange={e => {
                    setCurrentInformation({
                      ...currentInformation,
                      dataAberturaConta: e.target.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="paginaWeb"
                  name="paginaWeb"
                  label="Página Web"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa.pessoaContatos[0].paginaWeb}
                  onChange={e => {
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoaContatos:
                          currentInformation.pessoa.pessoaContatos.map(
                            newContact => ({
                              ...newContact,
                              paginaWeb: e.target.value,
                            }),
                          ),
                      },
                    });
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Typography variant="h6" gutterBottom>
                  Dados da conta
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  required
                  id="banco"
                  name="banco"
                  label="Banco"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.clienteContas[0]?.banco}
                  fullWidth
                  autoComplete="shipping postal-code"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  required
                  id="agencia"
                  name="agencia"
                  label="Agencia"
                  value={currentInformation.clienteContas[0]?.agencia}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  required
                  id="conta"
                  name="conta"
                  label="Conta"
                  value={currentInformation.clienteContas[0]?.conta}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  autoComplete="shipping postal-code"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  required
                  id="dgconta"
                  name="dgconta"
                  label="Digito Conta"
                  value={currentInformation.clienteContas[0]?.digitoConta}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  autoComplete="shipping postal-code"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Tipo de Controle
                </InputLabel>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullwidth
                  className={classes.formControl}
                >
                  <Select
                    displayEmpty
                    value={tipoControle}
                    onChange={e => {
                      setCurrentInformation({
                        ...currentInformation,
                        clienteContas: currentInformation.clienteContas.map(
                          accountClient => ({
                            ...accountClient,
                            tipoControle: e.target.value,
                          }),
                        ),
                      });
                    }}
                  >
                    <MenuItem value="INTERNO">Interno (Multiplica)</MenuItem>
                    <MenuItem value="EXTERNO">Externo (Cliente)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              style={{ marginBottom: '20px' }}
            >
              Dados do Representante
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={eSolidaria}
                      // onChange={event => setChecked(event.target.checked)}
                      onChange={event =>
                        setCurrentInformation({
                          ...currentInformation,
                          clienteContas: currentInformation.clienteContas.map(
                            accountClient => ({
                              ...accountClient,
                              assinaturaSolidaria: event.target.checked,
                            }),
                          ),
                        })
                      }
                    />
                  }
                  label="Assinatura solidária (representantes assinam em conjunto)"
                />
                {/* <Typography>
                    Assinatura solidária (representantes assinam em conjunto)
                  </Typography> */}
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  required
                  id="cpf"
                  name="cpf"
                  label="CPF"
                  value={currentInformation.clienteCorrentistas[0]?.cpf}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="rg"
                  name="rg"
                  label="RG"
                  value={currentInformation.clienteCorrentistas[0]?.rg}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      clienteCorrentistas:
                        currentInformation.clienteCorrentistas.map(
                          (correntistClient, index) => {
                            if (index === 0) {
                              return {
                                ...correntistClient,
                                rg: e.target.value,
                              };
                            } else {
                              return {
                                ...correntistClient,
                              };
                            }
                          },
                        ),
                    })
                  }
                  fullWidth
                  autoComplete="shipping postal-code"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="nome"
                  name="nome"
                  label="Nome"
                  value={currentInformation.clienteCorrentistas[0]?.nome}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      clienteCorrentistas:
                        currentInformation.clienteCorrentistas.map(
                          (correntistClient, index) => {
                            if (index === 0) {
                              return {
                                ...correntistClient,
                                nome: e.target.value,
                              };
                            } else {
                              return {
                                ...correntistClient,
                              };
                            }
                          },
                        ),
                    })
                  }
                  fullWidth
                  autoComplete="shipping postal-code"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="email"
                  name="email"
                  label="Email"
                  value={
                    currentInformation.clienteCorrentistas[0]?.contato?.email
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      clienteCorrentistas:
                        currentInformation.clienteCorrentistas.map(
                          (correntistClient, index) => {
                            if (index === 0) {
                              return {
                                ...correntistClient.contato,
                                email: e.target.value,
                              };
                            } else {
                              return {
                                ...correntistClient,
                              };
                            }
                          },
                        ),
                    })
                  }
                  fullWidth
                  autoComplete="shipping postal-code"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="redeSocial"
                  label="Rede Social"
                  fullWidth
                  name="redeSocial"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    currentInformation.clienteCorrentistas[0]?.contato
                      ?.redeSocial
                  }
                  onChange={e => {
                    setCurrentInformation({
                      ...currentInformation,
                      clienteCorrentistas: {
                        ...currentInformation.clienteCorrentistas[0].contato,
                        redeSocial: e.target.value,
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={1}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  label="DDD"
                  name="ddd1"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    currentInformation.clienteCorrentistas[0]?.contato?.ddd
                  }
                  onChange={e => {
                    setCurrentInformation({
                      ...currentInformation,
                      clienteCorrentistas: {
                        ...currentInformation.clienteCorrentistas[0].contato,
                        ddd: e.target.value,
                      },
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  label="Telefone"
                  name="telefone1"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    currentInformation.clienteCorrentistas[0]?.contato?.telefone
                  }
                  onChange={e => {
                    setCurrentInformation({
                      ...currentInformation,
                      clienteCorrentistas: {
                        ...currentInformation.clienteCorrentistas[0].contato,
                        telefone: e.target.value,
                      },
                    });
                  }}
                />
              </Grid>
            </Grid>

            {accountInformation?.clienteCorrentistas?.length > 1 ||
            showNovoCorrentista ? (
              <Grid container spacing={3} style={{ marginTop: '20px' }}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6" gutterBottom>
                    Dados do Segundo Representante
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    disabled
                    variant="outlined"
                    size="small"
                    required
                    id="cpf"
                    name="cpf"
                    label="CPF"
                    value={currentInformation.clienteCorrentistas[1]?.cpf}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    id="rg"
                    name="rg"
                    label="RG"
                    value={currentInformation.clienteCorrentistas[1]?.rg}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e =>
                      setCurrentInformation({
                        ...currentInformation,
                        clienteCorrentistas:
                          currentInformation.clienteCorrentistas.map(
                            (correntistClient, index) => {
                              if (index === 1) {
                                return {
                                  ...correntistClient,
                                  rg: e.target.value,
                                };
                              } else {
                                return {
                                  ...correntistClient,
                                };
                              }
                            },
                          ),
                      })
                    }
                    fullWidth
                    autoComplete="shipping postal-code"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    id="nome"
                    name="nome"
                    label="Nome"
                    value={currentInformation.clienteCorrentistas[1]?.nome}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e =>
                      setCurrentInformation({
                        ...currentInformation,
                        clienteCorrentistas:
                          currentInformation.clienteCorrentistas.map(
                            (correntistClient, index) => {
                              if (index === 1) {
                                return {
                                  ...correntistClient,
                                  nome: e.target.value,
                                };
                              } else {
                                return {
                                  ...correntistClient,
                                };
                              }
                            },
                          ),
                      })
                    }
                    fullWidth
                    autoComplete="shipping postal-code"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    id="email"
                    name="email"
                    label="Email"
                    value={
                      currentInformation.clienteCorrentistas[1]?.contato?.email
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    autoComplete="shipping postal-code"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    id="redeSocial"
                    label="Rede Social"
                    fullWidth
                    name="redeSocial"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      currentInformation.clienteCorrentistas[1]?.contato
                        ?.redeSocial
                    }
                    onChange={e => {
                      setCurrentInformation({
                        ...currentInformation,
                        clienteCorrentistas: {
                          ...currentInformation.clienteCorrentistas[1].contato,
                          redeSocial: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    label="DDD"
                    name="ddd1"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      currentInformation.clienteCorrentistas[1]?.contato?.ddd
                    }
                    onChange={e => {
                      setCurrentInformation({
                        ...currentInformation,
                        clienteCorrentistas: {
                          ...currentInformation.clienteCorrentistas[1].contato,
                          ddd: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    label="Telefone"
                    name="telefone1"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      currentInformation.clienteCorrentistas[1]?.contato
                        ?.telefone
                    }
                    onChange={e => {
                      setCurrentInformation({
                        ...currentInformation,
                        clienteCorrentistas: {
                          ...currentInformation.clienteCorrentistas[1].contato,
                          telefone: e.target.value,
                        },
                      });
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Box>
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              style={{ marginBottom: '20px' }}
            >
              Dados de Endereço
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="cep"
                  label="CEP"
                  fullWidth
                  name="cep"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa?.pessoasEndereco[0]?.cep}
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoasEndereco:
                          currentInformation.pessoa.pessoasEndereco.map(
                            (address, index) => {
                              if (index === 0) {
                                return {
                                  ...address,
                                  cep: e.target.value,
                                };
                              } else {
                                return {
                                  ...address,
                                };
                              }
                            },
                          ),
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="logradouro"
                  label="Logradouro"
                  fullWidth
                  name="logradouro"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    currentInformation.pessoa?.pessoasEndereco[0]?.endereco
                  }
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoasEndereco:
                          currentInformation.pessoa.pessoasEndereco.map(
                            (address, index) => {
                              if (index === 0) {
                                return {
                                  ...address,
                                  endereco: e.target.value,
                                };
                              } else {
                                return {
                                  ...address,
                                };
                              }
                            },
                          ),
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  id="complemento"
                  label="Complemento"
                  fullWidth
                  name="complemento"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    currentInformation.pessoa?.pessoasEndereco[0]?.complemento
                  }
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoasEndereco:
                          currentInformation.pessoa.pessoasEndereco.map(
                            (address, index) => {
                              if (index === 0) {
                                return {
                                  ...address,
                                  complemento: e.target.value,
                                };
                              } else {
                                return {
                                  ...address,
                                };
                              }
                            },
                          ),
                      },
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="numero"
                  label="Número"
                  fullWidth
                  name="numero"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    currentInformation.pessoa?.pessoasEndereco[0]?.enderecoNum
                  }
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoasEndereco:
                          currentInformation.pessoa.pessoasEndereco.map(
                            (address, index) => {
                              if (index === 0) {
                                return {
                                  ...address,
                                  enderecoNum: e.target.value,
                                };
                              } else {
                                return {
                                  ...address,
                                };
                              }
                            },
                          ),
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="bairro"
                  label="Bairro"
                  fullWidth
                  name="bairro"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa?.pessoasEndereco[0]?.bairro}
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoasEndereco:
                          currentInformation.pessoa.pessoasEndereco.map(
                            (address, index) => {
                              if (index === 0) {
                                return {
                                  ...address,
                                  bairro: e.target.value,
                                };
                              } else {
                                return {
                                  ...address,
                                };
                              }
                            },
                          ),
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="cidade"
                  label="Cidade"
                  fullWidth
                  name="cidade"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa?.pessoasEndereco[0]?.cidade}
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoasEndereco:
                          currentInformation.pessoa.pessoasEndereco.map(
                            (address, index) => {
                              if (index === 0) {
                                return {
                                  ...address,
                                  cidade: e.target.value,
                                };
                              } else {
                                return {
                                  ...address,
                                };
                              }
                            },
                          ),
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="uf"
                  label="UF"
                  fullWidth
                  name="uf"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa?.pessoasEndereco[0]?.uf}
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoasEndereco:
                          currentInformation.pessoa.pessoasEndereco.map(
                            (address, index) => {
                              if (index === 0) {
                                return {
                                  ...address,
                                  uf: e.target.value,
                                };
                              } else {
                                return {
                                  ...address,
                                };
                              }
                            },
                          ),
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="pais"
                  label="País"
                  fullWidth
                  name="pais"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa?.pessoasEndereco[0]?.pais}
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoasEndereco:
                          currentInformation.pessoa.pessoasEndereco.map(
                            (address, index) => {
                              if (index === 0) {
                                return {
                                  ...address,
                                  pais: e.target.value,
                                };
                              } else {
                                return {
                                  ...address,
                                };
                              }
                            },
                          ),
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Tipo de Endereço
                </InputLabel>
                <FormControl
                  variant="outlined"
                  size="small"
                  className={classes.formControl}
                >
                  <Select
                    displayEmpty
                    value={
                      currentInformation.pessoa?.pessoasEndereco[0]
                        ?.tipoEndereco || ''
                    }
                    onChange={e =>
                      setCurrentInformation({
                        ...currentInformation,
                        pessoa: {
                          ...currentInformation.pessoa,
                          pessoasEndereco:
                            currentInformation.pessoa.pessoasEndereco.map(
                              (address, index) => {
                                if (index === 0) {
                                  return {
                                    ...address,
                                    tipoEndereco: e.target.value,
                                  };
                                } else {
                                  return {
                                    ...address,
                                  };
                                }
                              },
                            ),
                        },
                      })
                    }
                  >
                    <MenuItem value="RESIDENCIAL">Residencial</MenuItem>
                    <MenuItem value="COMERCIAL">Comercial</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Tipo de Residencia
                </InputLabel>
                <FormControl
                  variant="outlined"
                  size="small"
                  className={classes.formControl}
                >
                  <Select
                    displayEmpty
                    value={
                      currentInformation.pessoa?.pessoasEndereco[0]
                        ?.tipoResidencia || ''
                    }
                    onChange={e =>
                      setCurrentInformation({
                        ...currentInformation,
                        pessoa: {
                          ...currentInformation.pessoa,
                          pessoasEndereco:
                            currentInformation.pessoa.pessoasEndereco.map(
                              (address, index) => {
                                if (index === 0) {
                                  return {
                                    ...address,
                                    tipoResidencia: e.target.value,
                                  };
                                } else {
                                  return {
                                    ...address,
                                  };
                                }
                              },
                            ),
                        },
                      })
                    }
                  >
                    <MenuItem value="RESIDENCIA PROPRIA">Própria</MenuItem>
                    <MenuItem value="ALUGADA">Alugada</MenuItem>
                    <MenuItem value="FINANCIADA">Financiada</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '20px',
                }}
              >
                <br />
                <Link color="inherit" href="/backoffice/pendencias-abertura">
                  <LoadingBtn
                    type="submit"
                    style={{
                      float: 'right',
                      textTransform: 'none',
                      marginRight: '10px',
                    }}
                    variant="contained"
                    color="default"
                    className={classes.button}
                  >
                    Cancelar
                  </LoadingBtn>
                </Link>
                <LoadingBtn
                  type="submit"
                  style={{
                    float: 'right',
                    textTransform: 'none',
                    margin: 'auto',
                  }}
                  variant="contained"
                  color="primary"
                  isLoading={loadButton}
                  startIcon={!loadButton && <SaveIcon />}
                  className={classes.button}
                  onClick={salvarEdicao}
                >
                  Salvar
                </LoadingBtn>
              </Grid>
            </Grid>
          </Box>
        </React.Fragment>
      </Container>
    </Wrapper>
  );
}
