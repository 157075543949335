/* eslint-disable */
import React from 'react';
import './index.css';
import Routes from './routes/index';
import { ToastContainer, toast } from 'react-toastify';
import { UserProvider } from './hooks/useUser';
import {
  createTheme,
  ThemeProvider,
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale/';
import { CircularProgress } from '@material-ui/core';
import { getTheme } from './theme/api.theme';
import store from './store';
import { Provider, useSelector } from 'react-redux';

const generateClassName = createGenerateClassName({
  productionPrefix: 'solucoes',
  seed: 'backOffice',
});

const Main = () => {
  const [themeApp, setThemeApp] = React.useState(null);
  const { modeTheme, previewTheme } = useSelector(state => state.app);
  React.useEffect(() => {
    getTheme()
      .then(theme => {
        theme.palette.type = modeTheme;
        localStorage.setItem('app-config', JSON.stringify(theme));
        const customTheme = createTheme({ ...theme }, ptBR);
        setThemeApp(customTheme);
      })
      .catch(erro => {
        console.log('erro para pegar tema', erro);
        window.location.href = '/login?contexto=backoffice';
      });
  }, [modeTheme]);
  localStorage.setItem('entrei', true);
  React.useEffect(() => {
    console.log('mudou o tema', previewTheme);
    if (!!previewTheme) {
      const customTheme = createTheme({ ...previewTheme }, ptBR);
      setThemeApp(customTheme);
    }
  }, [previewTheme]);

  return (
    <StylesProvider generateClassName={generateClassName}>
      {themeApp && (
        <ThemeProvider theme={themeApp}>
          <UserProvider>
            <ToastContainer />
            <Routes />
          </UserProvider>
        </ThemeProvider>
      )}
    </StylesProvider>
  );
};

function Index() {
  return (
    <Provider store={store}>
      <Main />
    </Provider>
  );
}

export default Index;
