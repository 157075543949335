/* eslint react/prop-types: 0 */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Container } from './styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { useStylesDatagrid } from '../../DataGrid/DataGridWrapper/style';

const ConfirmButton = ({
  variant,
  color,
  disabled,
  children,
  title,
  placement,
  width,
  isLoading,
  startIcon,
  ...rest
}) => {
  const classes = useStylesDatagrid();

  return (
    <Container>
      <Tooltip
        title={title ? title : children}
        placement={placement ? placement : 'bottom'}
      >
        <IconButton
          disabled={disabled}
          isLoading={isLoading}
          variant="contained"
          color="primary"
          className={classes.loadingButton}
          {...rest}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={24} circleDisableShrink />
          ) : (
            startIcon
          )}
        </IconButton>
      </Tooltip>
    </Container>
  );
};

export default ConfirmButton;
