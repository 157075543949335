import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useEffect } from 'react';

import Backoffice from '../pages/Backoffice';
import Introducing from '../pages/Introducing';
import PendenciasAbertura from '../pages/PendenciasAbertura';
import DetalhesCliente from '../pages/DetalhesCliente';
import NewDetalhesCliente from '../pages/NewDetalhesCliente';
import Esteira from '../pages/Esteira';
import CadastroEventos from '../pages/Eventos/Cadastro';
import Parametros from '../pages/Parametros';
import CadastroAvisos from '../pages/Avisos/Cadastro';
import EditarAvisos from '../pages/Avisos/Editar';
import ListaAvisos from '../pages/Avisos/Lista';
import EditaEventos from '../pages/Eventos/Edicao';
import ListaEventos from '../pages/Eventos/Lista';
import CadastroBancos from '../pages/Bancos/Cadastro';
import EditaBancos from '../pages/Bancos/Edicao';
import ListaBancos from '../pages/Bancos/Lista';
import Configurations from '../pages/Configurations';
import PdfMonitor from '../pages/PdfMonitor';
import CadastroAssociacaoContas from '../pages/AssociacaoContas/Cadastro';
import EditaAssociacaoContas from '../pages/AssociacaoContas/Edicao';
import ListaAssociacaoContas from '../pages/AssociacaoContas/Lista';
import ConsultaAssociacaoContas from '../pages/AssociacaoContas/Consulta';
import CadastroAssociacaoBancos from '../pages/AssociacaoBancos/Cadastro';
import EditaAssociacaoBancos from '../pages/AssociacaoBancos/Edicao';
import ListaAssociacaoBancos from '../pages/AssociacaoBancos/Lista';
import ConsultaAssociacaoBancos from '../pages/AssociacaoBancos/Consulta';
import { DesbloqueioUser } from '../pages/DesbloqueioUsuario';
import { Users } from '../pages/usuario';
import { Usuarios } from '../pages/Usuarios';
import { VinculoUsuarios } from '../pages/VinculoUsuarios';
import { VinculoConta } from '../pages/VinculoConta';
import { VinculoEdit } from '../pages/VinculoEdit';
import { VinculoContaEdit } from '../pages/VinculoContaEdit';
import { EdicaoUsuarios } from '../pages/EdicaoUsuario';
import { RegistrarSenha } from '../pages/ConfirmSenha';
import { NewDetails } from '../pages/NewDetailCliente';

import PainelSaldos from '../pages/PainelSaldos';
import MonitorAprovacao from '../pages/MonitorAprovacao';
import HistoricoTeds from '../pages/HistoricoTeds';

function Routes() {
  const isProfileBackOffice = localStorage.getItem('profileBackOffice');
  const administradorEscrow = localStorage.getItem('administradorEscrow');

  useEffect(() => {
    if (isProfileBackOffice == 'false' && administradorEscrow != 'true') {
      window.location.href = '/login';
    }
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/backoffice" exact component={Introducing} />
        <Route path="/backoffice/new-backoffice" exact component={Backoffice} />
        <Route
          path="/backoffice/pendencias-abertura"
          exact
          component={PendenciasAbertura}
        />
        <Route
          path="/backoffice/detalhes-cliente"
          component={DetalhesCliente}
        />
        <Route
          path="/backoffice/new-detalhes-cliente"
          component={NewDetalhesCliente}
        />
        <Route path="/backoffice/esteira-back" component={Esteira} />
       
        <Route path="/backoffice/pwd" component={DesbloqueioUser} />

        <Route
          path="/backoffice/eventos-cadastro"
          component={CadastroEventos}
        />
        <Route path="/backoffice/parametros" component={Parametros} />
        <Route path="/backoffice/avisos-lista" component={ListaAvisos} />
        <Route path="/backoffice/avisos-cadastro" component={CadastroAvisos} />
        <Route path="/backoffice/avisos-editar" component={EditarAvisos} />
        <Route path="/backoffice/configurations" component={Configurations} />
        <Route path="/backoffice/eventos-editar" component={EditaEventos} />
        <Route path="/backoffice/eventos-lista" component={ListaEventos} />
        <Route path="/backoffice/bancos-cadastro" component={CadastroBancos} />
        <Route path="/backoffice/bancos-editar" component={EditaBancos} />
        <Route path="/backoffice/bancos-lista" component={ListaBancos} />
        <Route
          path="/backoffice/associacao-contas-cadastro"
          component={CadastroAssociacaoContas}
        />
        <Route
          path="/backoffice/associacao-contas-editar"
          component={EditaAssociacaoContas}
        />
        <Route
          path="/backoffice/associacao-contas-lista"
          component={ListaAssociacaoContas}
        />
        <Route
          path="/backoffice/associacao-contas-consulta"
          component={ConsultaAssociacaoContas}
        />
        <Route
          path="/backoffice/associacao-bancos-cadastro"
          component={CadastroAssociacaoBancos}
        />
        <Route
          path="/backoffice/associacao-bancos-editar"
          component={EditaAssociacaoBancos}
        />
        <Route
          path="/backoffice/associacao-bancos-lista"
          component={ListaAssociacaoBancos}
        />
        <Route
          path="/backoffice/associacao-bancos-consulta"
          component={ConsultaAssociacaoBancos}
        />
        <Route path="/backoffice/usuario" component={Users} />
        <Route path="/backoffice/userlist" component={Usuarios} />
        <Route path="/backoffice/vinculouser" component={VinculoUsuarios} />
        <Route path="/backoffice/vinculo-conta" component={VinculoConta} />
        <Route path="/backoffice/vinculoedit" component={VinculoEdit} />
        <Route
          path="/backoffice/vinculo-conta-edit"
          component={VinculoContaEdit}
        />
        <Route path="/backoffice/edicao-usuarios" component={EdicaoUsuarios} />
        <Route path="/backoffice/newpassword" component={RegistrarSenha} />
        <Route path="/backoffice/pdf-monitor" component={PdfMonitor} />

        <Route path="/backoffice/newdetailuser" component={NewDetails} />
        <Route
          path="/backoffice/painel-saldos"
          exact
          component={PainelSaldos}
        />

        <Route
          path="/backoffice/monitor-aprovacao"
          exact
          component={MonitorAprovacao}
        />
        <Route
          path="/backoffice/historico-teds-backoffice"
          component={HistoricoTeds}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
