import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Cookies } from 'react-cookie';
import { baseUrl } from '../util/constants';

// const cookies = new Cookies()
// const tknAccess = cookies.get('tkn-access')
const tknAccess = localStorage.getItem('tkn-access');

//const baseUrl = 'https://multisolucoes.grupomultiplica.com.br';
// const baseUrl = 'https://2xcapital.grupomultiplica.com.br';
//const baseUrl = 'https://multipague-hml.grupomultiplica.com.br';

const createApiService = (service, headers) => {
  const apiAxios = axios.create({
    baseURL: baseUrl + service,
    headers: { Authorization: `Bearer ${tknAccess}`, ...headers },
  });
  apiAxios.interceptors.response.use(
    function (response) {
      return response;
    },
    error => {
      try {
        if (error.response?.status === 401 || error.request?.status === 0) {
          window.location.href = '/login?contexto=backoffice';
        }
      } catch (e) {}
      console.error('RequestApi', error.response.data);
      return Promise.reject(error);
    },
  );
  return apiAxios;
};

export const api_multipague_contaAbrir = createApiService(`/ms-conta/v1`);

export const api_multipague_conta = createApiService(`/ms-conta/v1`, {
  ContentType: 'application/json',
});

export const api_multipague_transacao = createApiService(`/ms-transacao/v1`, {
  IdempotencyKey: uuidv4(),
});

export const api_multipague_agenda_financeira = createApiService(
  `/ms-agenda-financeira/v1`,
);

export const api_multipague_cobranca = createApiService(`/ms-cobranca/v1`, {
  IdempotencyKey: uuidv4(),
  'X-ASSINATURA-FINANCEIRA': '81dc9bdb52d04dc20036dbd8313ed055',
});

export const api_multipague_configuracao2 =
  createApiService(`/ms-configuracao/v2`);

export const api_multipague_configuracao_aviso = createApiService(
  `/ms-configuracao/v1`,
  {
    'Content-Type': 'multipart/form-data',
  },
);

export const api_multipague_configuracao =
  createApiService(`/ms-configuracao/v1`);

export const api_multipague_cadastrar_senha =
  createApiService(`/ms-configuracao/v1`);

export const api_multipague_esteira = createApiService(`/ms-esteira/v1`);

export const api_multipague_despesa = createApiService(`/ms-despesa/v1`);

export const api_multipague_segmento_tarifador =
  createApiService(`/ms-cadastro/api/v1`);
