import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import { FaSave } from 'react-icons/fa';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import { Cookies } from 'react-cookie';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import 'react-toastify/dist/ReactToastify.css';
import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import { MdArrowBack } from 'react-icons/md';

import {
  api_multipague_configuracao,
  api_multipague_configuracao2,
} from '../../../services/api';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';

export default function CadastroEventos() {
  const history = useHistory();
  const cookies = new Cookies();
  const theme = useTheme();
  const [loadButton, setLoadButton] = useState(false);

  const eventoId = localStorage.getItem('assEditEventoId');
  const editCodigo = localStorage.getItem('assEditId');
  const editEvento = localStorage.getItem('assEditEvento');
  const editDescricao = localStorage.getItem('assEditBanco');
  const editAtivo = localStorage.getItem('assEditAtivo');
  const editParceiro = localStorage.getItem('assEditParceiro');
  const editIdEventoBanco = localStorage.getItem('assEditIdEventoBanco');

  const editVlrMin = localStorage.getItem('assEditVlrMin');
  const editVlrMax = localStorage.getItem('assEditVlrMax');
  const editVlrHoraMin = localStorage.getItem('assEditHoraMin');
  const editVlrHoraMax = localStorage.getItem('assEditHoraMax');
  const editDiasLimite = localStorage.getItem('assEditDias');

  const [selecionarConta, setSelecionarConta] = useState([]);
  const [descricao, setDescricao] = useState('');
  const [vlrMin, setVlrMin] = useState(editVlrMin);
  const [vlrMax, setVlrMax] = useState(editVlrMax);
  const [horaMin, setHoraMin] = useState(editVlrHoraMin);
  const [horaMax, setHoraMax] = useState(editVlrHoraMax);
  const [diasLimite, setDiasLimite] = useState(editDiasLimite);
  const [idEvento, setidEvento] = useState(eventoId);

  const [state, setState] = React.useState({
    checkedA: editParceiro,
  });
  const [valorInput, setValorInput] = useState({});
  localStorage.setItem('valorIdDetalhes', valorInput);

  const novoCadastro = {
    codigoEventoBanco: null,
    diasCompensacao: 0,
    horaMaximo: horaMax,
    horaMinimo: horaMin,
    idBanco: parseInt(editCodigo),
    idEvento: idEvento,
    quantidadeDiasLimitePagamento: parseInt(diasLimite),
    utilizaSenhaFinanceira: Boolean(state.checkedA),
    valorMaximo: parseInt(vlrMax),
    valorMinimo: parseInt(vlrMin),
  };

  const CustomSwitch = withStyles({
    switchBase: {
      color: '#6b6b6b',
      '&$checked': {
        color: theme.palette.primary.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  useEffect(() => {
    api_multipague_configuracao2
      .get(`/evento`)
      .then(response => {
        setSelecionarConta(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleSalvar = value => {
    setLoadButton(true);

    api_multipague_configuracao
      .put(`/eventoBanco/${editIdEventoBanco}`, novoCadastro)
      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        toastComponentSuccess('Dados editados com sucesso!', theme);
        setTimeout(function () {
          history.push('/backoffice/associacao-bancos-lista');
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };

  const handleDescricao = event => {
    setDescricao(event.target.value);
  };

  return (
    <Wrapper
      backButton
      title="Editar"
      crumb={[
        { link: '/backoffice', name: 'Home' },
        {
          link: '/backoffice/associacao-bancos-lista',
          name: 'Associação de Bancos',
        },
        { link: '/backoffice/associacao-bancos-consulta', name: 'Consulta' },
      ]}
    >
      <Box style={{ borderRadius: '20px' }}>
        <div style={{ height: '1rem' }} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Banco"
              id="outlined-size-small"
              value={editDescricao}
              onChange={handleDescricao}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              options={selecionarConta}
              getOptionLabel={option => option.descricao}
              onChange={(event, option) => {
                setidEvento(option.id);
              }}
              style={{ width: '100%' }}
              required
              renderInput={params => (
                <TextField
                  {...params}
                  label="Evento"
                  placeholder={editEvento}
                  InputLabelProps={{ shrink: true, required: true }}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <CurrencyTextField
              fullWidth
              label="Valor Mínimo"
              variant="outlined"
              size="small"
              value={vlrMin}
              decimalCharacter=","
              digitGroupSeparator="."
              currencySymbol="R$"
              outputFormat="string"
              onChange={(event, value) => setVlrMin(value)}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <CurrencyTextField
              fullWidth
              label="Valor Máximo"
              variant="outlined"
              size="small"
              value={vlrMax}
              decimalCharacter=","
              digitGroupSeparator="."
              currencySymbol="R$"
              outputFormat="string"
              onChange={(event, value) => setVlrMax(value)}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Horário min permitido para transações"
              id="outlined-size-small"
              type="time"
              value={horaMin}
              onChange={e => {
                setHoraMin(e.target.value);
              }}
              variant="outlined"
              size="small"
              InputLabelProps={{
                style: {
                  fontSize: '13px',
                },
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Horário max permitido para transações"
              id="outlined-size-small"
              type="time"
              value={horaMax}
              onChange={e => {
                setHoraMax(e.target.value);
              }}
              variant="outlined"
              size="small"
              InputLabelProps={{
                style: {
                  fontSize: '13px',
                },
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <TextField
              fullWidth
              label="Dias limite de pagamento"
              id="outlined-size-small"
              value={diasLimite}
              onChange={e => {
                setDiasLimite(e.target.value);
              }}
              variant="outlined"
              size="small"
              InputLabelProps={{
                style: {
                  fontSize: '13px',
                },
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <FormLabel component="legend">Senha</FormLabel>
            <CustomSwitch
              checked={state.checkedA}
              onChange={e => {
                setState({ ...state, [e.target.name]: e.target.checked });
              }}
              color="primary"
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: '20px' }}>
          <Grid item xs={12} md={12}></Grid>
          <div
            style={{
              margin: '10px',
              width: '100%',
              marginTop: '1rem',
              flexWrap: 'wrap',
              display: 'flex',
              gap: '1rem',
              justifyContent: 'flex-end',
            }}
          >
            <LoadingBtn
              variant="contained"
              color="disabled"
              width="115"
              title={'Voltar'}
              startIcon={<MdArrowBack color="background" />}
              onClick={() => history.go(-1)}
            >
              Voltar
            </LoadingBtn>
            <LoadingBtn
              variant="contained"
              title={'Salvar'}
              color="primary"
              width="115"
              isLoading={loadButton}
              startIcon={
                !loadButton && <FaSave size="1em" color="background" />
              }
              onClick={() => {
                handleSalvar();
              }}
            >
              Salvar
            </LoadingBtn>
          </div>
        </Grid>
      </Box>
    </Wrapper>
  );
}
