import styled from 'styled-components';
import { Form } from '@unform/web';
import { Box, alpha, useTheme } from '@material-ui/core';
import { styled as MuiStyled } from '@material-ui/core';

export const Container = styled(Form)``;

export const Subcontainer = MuiStyled(Box)(({ theme }) => {
  return {
    padding: '20px',
    marginTop: '20px',
    borderRadius: '20px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: `${alpha(theme.palette.primary.dark, 0.2)} 0px 0px 45px -15px`,
    padding: '1.5rem',
  };
});

export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;

  p {
    font-size: 16px;
    font-weight: bold;
  }
`;
export const ContainerInputs = styled.div`
  display: grid;
  row-gap: 20px;
  padding: 20px;
  grid-template-columns: auto auto;
  column-gap: 20px;
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
export const ContainerButtons = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media only screen and (max-width: 600px) {
    justify-content: center;

    button {
      margin: 5px;
    }
  }
`;
export const Checks = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
`;
