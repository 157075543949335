import moment from 'moment';
import { api_multipague_conta } from '../../services/api';
import { maskCNPJ } from '../../util/mask';

const formatDadosSaldos = itemSaldo => {
  const item = {
    id: itemSaldo.conta,
    cnpj: maskCNPJ(itemSaldo.cliente.cnpjCpf),
    razaoSocial: itemSaldo.cliente.nome,
    dataAbertura: itemSaldo.cliente.dataAberturaConta
      ? moment(itemSaldo.cliente.dataAberturaConta).format('DD/MM/YYYY H:mm:ss')
      : '',
    conta: `${itemSaldo.agencia} ${itemSaldo.conta}-${itemSaldo.digitoConta} `,
    tipoControle: itemSaldo.tipoControle,
    dataUltimaMov: itemSaldo.saldo.dtUltAtualizacao
      ? moment(itemSaldo.saldo.dtUltAtualizacao).format('DD/MM/YYYY H:mm:ss')
      : '',
    saldo: itemSaldo.saldo.saldo,
    error: itemSaldo.saldo.mensagemErro,
  };
  return item;
};

export const getSaldos = async params => {
  const headers = { Accept: 'application/paged+json' };

  const { data } = await api_multipague_conta.get('/conta/ativos', {
    params,
    headers,
  });
  const saldos = {
    content: data.content.map(item => formatDadosSaldos(item)),
    total: data.totalElements,
  };
  console.log('🚀 ~ file: service.js:32 ~ getSaldos ~ saldos:', saldos);
  return saldos;
};
