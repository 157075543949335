import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

import ContainerComponent from '../../components/Container';
export const NewDetails = () => {
  const { handleSubmit, control, setValue } = useForm();

  const sendReport = async () => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    // setValue('InputComponent.sobrenome', 'Luo', {
    //   shouldValidate: true,
    //   shouldDirty: true,
    // });
    setValue('InputComponent', { nome: '1', sobrenome: '2' });
  };

  useEffect(() => {
    // setTimeout(() => {
    //   setValue('InputComponent', 'Luo', {
    //     shouldValidate: true,
    //     shouldDirty: true,
    //   });
    // }, 2000);
    sendReport();
  }, []);

  const [age, setAge] = React.useState('');
  const handleChange = event => {
    console.log(event.target.value);
    setAge(event.target.value);
  };
  const root = {
    path: [
      {
        name: 'Esteira',
        caminho: '/backoffice/esteira-back',
      },
    ],
    pagAtual: 'Pesquisar',
  };

  function submit(data) {
    console.log(data);
  }

  return (
    <ContainerComponent root={root}>
      <form onSubmit={handleSubmit(submit)}>
        <Controller
          name="InputComponent.nome"
          control={control}
          defaultValue=""
          render={({ field }) => <TextField {...field} />}
        />
        <Controller
          name="InputComponent.sobrenome"
          control={control}
          defaultValue=""
          render={({ field }) => <TextField {...field} />}
        />
        <Controller
          name="selecteComponent"
          control={control}
          render={({ field }) => (
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={age}
              onChange={handleChange}
              label="Age"
              {...field}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          )}
        />
        <Controller
          name="CheckboxComponent"
          control={control}
          defaultValue={false}
          rules={{ required: true }}
          render={({ field }) => <Checkbox {...field} />}
        />
        <input type="submit" />
        <button type="submit">Editar</button>
      </form>
    </ContainerComponent>
  );
};
