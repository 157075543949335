import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { FaSave } from 'react-icons/fa';
import LoadingBtn from '../../../components/Buttons/ConfirmButton';
import Wrapper from '../../../components/Wrapper';
import Box from '../../../components/Box';
import { MdArrowBack } from 'react-icons/md';
import 'react-toastify/dist/ReactToastify.css';

import {
  api_multipague_configuracao,
  api_multipague_conta,
} from '../../../services/api';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../../components/Toast';

const useStyles = makeStyles(theme => ({
  grid: {
    marginBottom: '20px',
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
    minWidth: 120,
  },
}));

export default function AssociacaoContasEditar() {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [loadButton, setLoadButton] = useState(false);

  const [selecionarConta, setSelecionarConta] = useState([]);
  const [faixas, setFaixas] = useState([]);
  const [segmento, setSegmento] = useState(
    localStorage.getItem('ass-contas-consulta-faixa'),
  );
  const idConsulta = localStorage.getItem('idConsulta');
  const [descricao, setDescricao] = useState(
    localStorage.getItem('ass-contas-consulta-descricao'),
  );
  const [valorMinimo, setValorMinimo] = useState(
    localStorage.getItem('ass-contas-consulta-vlrMinimo') / 100,
  );
  const [valorMaximo, setValorMaximo] = useState(
    localStorage.getItem('ass-contas-consulta-vlrMaximo') / 100,
  );
  const [valorTarifa, setValorTarifa] = useState(
    localStorage.getItem('ass-contas-consulta-vlrTarifa'),
  );
  const [codigo, setCodigo] = useState(
    localStorage.getItem('ass-contas-consulta-codigo'),
  );
  const [codigoEvento, setCodigoEvento] = useState(
    localStorage.getItem('ass-contas-consulta-codigoEvento'),
  );
  const [identificadorEvento, setIdentificadorEvento] = useState(
    localStorage.getItem('ass-contas-consulta-identificadorEvento'),
  );
  const [codigoClienteConta, setCodigoClienteConta] = useState(
    localStorage.getItem('ass-contas-consulta-clienteConta'),
  );
  const [esteira, setEsteira] = React.useState(
    JSON.parse(localStorage.getItem('ass-contas-consulta-esteira')),
  );

  const [valorInput, setValorInput] = useState({});
  localStorage.setItem('valorIdDetalhes', valorInput);

  const novoCadastro = {
    codigoClienteConta: Number(codigoClienteConta),
    codigoEvento: codigoEvento,
    faixaTarifa: segmento,
    utilizaEsteira: esteira,
    valorMaximo: Number(valorMaximo),
    valorMinimo: Number(valorMinimo),
    valorTarifa: Number(valorTarifa),
  };

  const CustomSwitch = withStyles({
    switchBase: {
      color: '#6b6b6b',
      '&$checked': {
        color: theme.palette.primary.main,
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    checked: {},
    track: {},
  })(Switch);

  useEffect(() => {
    api_multipague_configuracao
      .get(`/tipoConta`)
      .then(response => {
        setSelecionarConta(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleSalvar = value => {
    console.log('savando', novoCadastro);
    setLoadButton(true);

    api_multipague_configuracao
      .put(`/eventoClienteConta/${codigo}`, novoCadastro)
      .then(function (response) {
        setLoadButton(false);
        console.log(response);
        toastComponentSuccess('Dados editados com sucesso!', theme);
        setTimeout(function () {
          history.push('/backoffice/associacao-contas-consulta');
        }, 3000);
      })
      .catch(function (error) {
        setLoadButton(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 500
        ) {
          toastComponentError(error.response.data.mensagem, theme);
        }
      });
  };
  const handleCodigo = event => {
    setCodigo(event.target.value);
  };
  const handleEsteira = event => {
    setEsteira(event.target.checked);
  };
  const handleDescricao = event => {
    setDescricao(event.target.value);
  };

  useEffect(() => {
    api_multipague_conta
      .get(
        `/conta/tarifador/faixas-tarifa?idClienteConta=${idConsulta}&identificadorEvento=${identificadorEvento}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(response => {
        const selectFilterTar = response.data;
        const aquiSelectTar = selectFilterTar.map(key => {
          console.log('VALUE SEGMENTO: ', selectFilterTar);
          return (
            <MenuItem key={key} name={key} value={key}>
              {key}
            </MenuItem>
          );
        });
        setFaixas(aquiSelectTar);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <Wrapper
      backButton
      title="Editar"
      crumb={[
        { link: '/backoffice', name: 'Home' },
        {
          link: '/backoffice/associacao-contas-lista',
          name: 'Associação de Contas',
        },
        { link: '/backoffice/associacao-contas-consulta', name: 'Consulta' },
      ]}
    >
      <Box style={{ borderRadius: '20px' }}>
        <div style={{ height: '1rem' }} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3}>
            <FormLabel component="legend">Código</FormLabel>
            <TextField
              fullWidth
              id="outlined-size-small"
              onChange={handleCodigo}
              value={codigo}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <FormLabel component="legend">Descrição</FormLabel>
            <TextField
              fullWidth
              id="outlined-size-small"
              value={descricao}
              onChange={handleDescricao}
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <FormLabel component="legend">Valor Mínimo</FormLabel>
            <CurrencyTextField
              fullWidth
              variant="outlined"
              size="small"
              value={valorMinimo}
              decimalCharacter=","
              digitGroupSeparator="."
              currencySymbol="R$"
              outputFormat="string"
              onChange={(event, value) => setValorMinimo(value)}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <FormLabel component="legend">Valor Máximo</FormLabel>
            <CurrencyTextField
              fullWidth
              variant="outlined"
              size="small"
              value={valorMaximo}
              decimalCharacter=","
              digitGroupSeparator="."
              currencySymbol="R$"
              outputFormat="string"
              onChange={(event, value) => setValorMaximo(value)}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <InputLabel htmlFor="outlined-age-native-simple-seg-zero">
              Faixa
            </InputLabel>
            <FormControl
              variant="outlined"
              size="small"
              fullwidth
              className={classes.formControl}
            >
              <Select
                displayEmpty
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={segmento}
                onChange={e => setSegmento(e.target.value)}
              >
                {faixas}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <InputLabel htmlFor="outlined-age-native-simple-seg-zero">
              Valor Tarifa
            </InputLabel>
            <CurrencyTextField
              fullWidth
              variant="outlined"
              size="small"
              value={valorTarifa}
              decimalCharacter=","
              digitGroupSeparator="."
              currencySymbol="R$"
              outputFormat="string"
              onChange={(event, value) => setValorTarifa(value)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <FormLabel component="legend">Esteira</FormLabel>
            <CustomSwitch
              checked={esteira}
              onChange={handleEsteira}
              color="primary"
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.grid}>
          <Grid item xs={12} md={12}></Grid>
          <div
            style={{
              margin: '10px',
              width: '100%',
              marginTop: '1rem',
              display: 'flex',
              gap: '1rem',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <LoadingBtn
              variant="contained"
              color="disabled"
              width="115"
              title={'Voltar'}
              startIcon={<MdArrowBack color="background" />}
              onClick={() => {
                history.go(-1);
              }}
            >
              Voltar
            </LoadingBtn>
            <LoadingBtn
              variant="contained"
              title={'Salvar'}
              color="primary"
              width="115"
              isLoading={loadButton}
              startIcon={
                !loadButton && <FaSave size="1em" color="background" />
              }
              onClick={() => {
                handleSalvar();
              }}
            >
              Salvar
            </LoadingBtn>
          </div>
        </Grid>
      </Box>
    </Wrapper>
  );
}
