import { styled, Box } from '@material-ui/core';
export const Container = styled(Box)(({ theme }) => {
  return {
    height: '100vh',
    paddingTop: "4rem",
    position: 'fixed',
    overflow: 'scroll',
    backgroundColor: theme.palette.appBar[theme.palette.type].bgMenu,
    '&::-webkit-scrollbar': {
      width: '0.1px',
      maxHeight: '5px',
    },
    '&::-webkit-scrollbar-track-piece': {
      backgroundColor:
        theme.palette.type === 'dark'
          ? theme.palette.background.dark
          : theme.palette.background.default,
      '-webkitBorderRadius': '6px',
    },
    '&::-webkit-scrollbar-thumb:vertical': {
      backgroundColor: theme.palette.primary.main,
      '-webkit-border-radius': '6px',
    },
    scrollbarColor: `${theme.palette.primary.main} #fff`,
    scrollbarWidth: 'thin !important',
  };
});
