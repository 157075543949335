import React, { useEffect, useState } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Switch from '@material-ui/core/Switch';
import Wrapper from '../../components/Wrapper';
import Container from '@material-ui/core/Container';
import FormGroup from '@material-ui/core/FormGroup';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '../../components/Box';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import {
  api_multipague_configuracao,
  api_multipague_conta,
} from '../../services/api';
import { Cookies } from 'react-cookie';
import LoadingBtn from '../../components/Buttons/ConfirmButton';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import { CircularProgress, ListItemText, alpha } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { MdClear, MdSave } from 'react-icons/md';
import { perfilMaster } from '../../util/Auth';
import {
  toastComponentError,
  toastComponentSuccess,
} from '../../components/Toast';

const cookies = new Cookies();
const tknAccess = cookies.get('tkn-access');

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
    minWidth: 120,
  },
  inputBloq: {
    display: 'none',
  },
  inputFree: {
    display: 'block',
  },
  boxContainer: {
    borderRadius: '10px',
    boxShadow: `${alpha(theme.palette.primary.dark, 0.2)} 0px 0px 45px -15px`,
  },
}));

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function TextMaskCustomDDD(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

TextMaskCustomDDD.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function TextMaskCustomCel(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

TextMaskCustomCel.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default function MeuPerfil() {
  const history = useHistory();
  const theme = useTheme();
  const condicaoBloqueado = situacao === 'BLOQUEADA' ? true : false;
  const classes = useStyles();
  const [state, setState] = React.useState({
    checkedA: condicaoBloqueado ? true : false,
  });
  const [eCelular, setEcelular] = useState();
  const [tipoControle, setTipoControle] = useState('');
  const [gerente, setGerente] = useState('');
  const [gerenteCedente, setGerenteCedente] = useState('');
  const [varGerenteComercial, setVarGerenteComercial] = useState('');
  const [gerentesComerciais, setGerentesComerciais] = useState('');
  const [associado, setAssociado] = useState('');
  const [associadoId, setAssociadoId] = useState();
  const [fundoValue, setAdministradorValue] = useState('');
  const [idAdmin, setAdministradorId] = useState(null);
  const [valorDoFundo, setValorDoFundo] = useState([]);
  const valorFundoTratado = [...new Set(valorDoFundo.map(item => item.cnpj))];
  const [segmento, setSegmento] = useState('');
  const [segmentoId, setSegmentoId] = useState('');
  const [eSolidaria, setESolidaria] = useState('');
  const [sms, setSms] = useState(false);
  const [alcada, setAlcada] = useState(false);
  const [registroBoleto, setRegistroBoleto] = useState();
  const [selectSegmento, setSelectSegmento] = useState([]);
  const [faixas, setFaixas] = useState([]);
  const [faixasBoleto, setFaixasBoleto] = useState([]);
  const [clienteCorrentista2Cpf, setClienteCorrentista2Cpf] = useState('');
  const [motivoBloqueio, setMotivoBloqueio] = useState('');
  const [newName, setNewName] = useState();
  const [userList, setUserList] = useState([]);
  const [administradores, setAdministradores] = useState([]);
  const [fundos, setFundos] = useState([]);
  const [allfundos, setAllFundos] = useState([]);
  const [editFundos, setEditFundos] = useState([]);
  const valorIdDetalhes = localStorage.getItem('valorIdDetalhes');
  const valorIdBloqueio = localStorage.getItem('valorIdBloqueio');
  const [loadButton, setLoadButton] = useState(false);
  const [situacao, setSituacao] = useState('');
  const [accountInformation, setAccountInformation] = useState();
  const [preencheuControle, setPreencheuControle] = useState('');
  const [preencheuSegmento, setPreencheuSegmento] = useState('');
  const [listaTarifas, setListaTarifas] = useState(false);
  const [isTarifado, setIsTarifado] = useState(false);
  const [mostrarTarifas, setMostrarTarifas] = useState();
  const [showNovoCorrentista, setShowNovoCorrentista] = useState(false);
  const [arrayEmptys, setArrayEmptys] = useState([]);
  const [currentInformation, setCurrentInformation] = useState({
    clienteContas: [],
    clienteCorrentistas: [],
    cnpjCpf: '',
    id: '',
    nome: '',
    dataAberturaConta: '',
    usuarioAberturaConta: '',
    situacao: '',
    pessoa: {
      id: '',
      nomeFantasia: '',
      documentoMunicipal: '',
      documentoEstadual: '',
      pessoasEndereco: [],
      pessoaContatos: [
        {
          contatoPrincipal: '',
          dataCadastro: '',
          ddd: '',
          ddi: '',
          email: '',
          id: '',
          nomeContato: '',
          paginaWeb: '',
          redeSocial: '',
          telefone: '',
          tipoTelefone: '',
          usuarioCadastro: '',
        },
      ],
      razaoSocial: '',
    },
  });

  const AuthStr = 'Bearer '.concat(tknAccess);
  const URL = `/conta/${valorIdDetalhes}/cliente`;

  useEffect(() => {
    api_multipague_conta
      .get(`/conta/tarifador/segmentos`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        const selectFilterTar = response.data;
        const aquiSelectTar = selectFilterTar.map(key => {
          const valueSegmento = JSON.stringify({
            codigoSegmento: key.id,
            descricaoSegmento: key.descricao,
          });
          return (
            <MenuItem key={key.id} name={key.id} value={valueSegmento}>
              {key.descricao}
            </MenuItem>
          );
        });
        setSelectSegmento(aquiSelectTar);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (segmento) {
      var newSegmento = JSON.parse(segmento);
      api_multipague_conta
        .get(
          `/conta/tarifador/ted/faixas-tarifa?segmento=${newSegmento.descricaoSegmento}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(response => {
          const selectFilterTar = response.data;
          const aquiSelectTar = selectFilterTar.map(key => {
            return (
              <MenuItem key={key} name={key} value={key}>
                {key}
              </MenuItem>
            );
          });
          setFaixas(aquiSelectTar);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [segmento, preencheuSegmento]);

  useEffect(() => {
    if (segmento) {
      var newSegmentoBol = JSON.parse(segmento);
      api_multipague_conta
        .get(
          `/conta/tarifador/pagamento-boleto/faixas-tarifa?segmento=${newSegmentoBol.descricaoSegmento}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(response => {
          const selectFilterTar = response.data;
          const aquiSelectTar = selectFilterTar.map(key => {
            return (
              <MenuItem key={key} name={key} value={key}>
                {key}
              </MenuItem>
            );
          });
          setFaixasBoleto(aquiSelectTar);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [segmento, preencheuSegmento]);

  useEffect(() => {
    api_multipague_conta
      .get(URL, {
        headers: {
          Authorization: AuthStr,
        },
      })
      .then(response => {
        const formatted = {
          cnpjCpf: response.data.cnpjCpf,
          id: response.data.id,
          nome: response.data.nome,
          usuarioAberturaConta: response.data.usuarioAberturaConta,
          gerenteComercial:
            response.data.gerenteComercial === null
              ? null
              : response.data.gerenteComercial,
          clienteContas: response.data?.clienteContas?.map(account => ({
            administrador: {
              idAdministradorFundo:
                account?.administradorFundo == null
                  ? ' '
                  : account?.administradorFundo.idAdministradorFundo,
              nomeAdministradorFundo:
                account?.administradorFundo == null
                  ? ' '
                  : account?.administradorFundo.nomeAdministradorFundo,
            },
            apelidoConta: account.apelidoConta,
            dataAbertura:
              account.dataAbertura != null
                ? moment(account.dataAbertura).format('YYYY-MM-DD')
                : null,
            fundos: account.fundos,
            tipoRegistroBoleto: account.tipoRegistroBoleto,
            assinaturaSolidaria: account.assinaturaSolidaria,
            agencia: account.agencia,
            id: account.id,
            conta: account.conta,
            digitoConta: account.digitoConta,
            banco: account?.banco.nome,
            situacao: account?.situacao,
            tokenPorTransacao: account?.tokenPorTransacao,
            notificaTransacoesPendentes: account?.notificaTransacoesPendentes,
            exigeAlcadaCredito: account?.exigeAlcadaCredito,
            tipoControle: account?.tipoControle,
            segmento: {
              codigoSegmento:
                account?.segmento == null
                  ? ''
                  : account.segmento.codigoSegmento,
              descricaoSegmento:
                account?.segmento == null
                  ? ''
                  : account.segmento.descricaoSegmento,
            },
            usuarioAdministrador: {
              idUsuarioAdministrador:
                account?.usuarioAdministrador == null
                  ? ' '
                  : account?.usuarioAdministrador.idUsuarioAdministrador,
              nomeUsuarioAdministrador:
                account?.usuarioAdministrador == null
                  ? ' '
                  : account?.usuarioAdministrador.nomeUsuarioAdministrador,
            },
          })),
          clienteCorrentistas: response.data?.clienteCorrentistas?.map(
            clients => ({
              contato: clients.contato,
              cpf: clients.cpf,
              dataCadastro: clients.dataCadastro,
              id: clients.id,
              nome: clients.nome,
              rg: clients.rg,
            }),
          ),
          pessoa: {
            pessoasEndereco: response.data.pessoa.pessoasEndereco.map(
              address => ({
                bairro: address.bairro,
                cep: address.cep,
                cidade: address.cidade,
                complemento: address.complemento,
                dataCadastro: address.dataCadastro,
                endereco: address.endereco,
                enderecoCorrespondencia: address.enderecoCorrespondencia,
                enderecoNum: address.enderecoNum,
                id: address.id,
                tipoEndereco: address.tipoEndereco,
                tipoResidencia: address.tipoResidencia,
                uf: address.uf,
                pais: '',
              }),
            ),
            pessoaContatos: response.data.pessoa.pessoaContatos.map(
              contact => ({
                contatoPrincipal: contact.contatoPrincipal,
                ddd: contact.ddd,
                email: contact.email,
                id: contact.id,
                nomeContato: contact.nomeContato,
                paginaWeb: contact.paginaWeb,
                redeSocial: contact.redeSocial,
                telefone: contact.telefone,
              }),
            ),
            id: response.data?.pessoa?.id,
            razaoSocial:
              response.data?.cnpjCpf.length < 14
                ? response.data?.nome
                : response.data?.pessoa?.razaoSocial,
            nomeFantasia: response.data?.pessoa?.nomeFantasia,
            documentoMunicipal: response.data?.pessoa?.documentoMunicipal,
            documentoEstadual: response.data?.pessoa?.documentoEstadual,
          },
          situacao: response.data?.situacao,
        };
        setCurrentInformation(formatted);
        setValorDoFundo(formatted.clienteContas[0]?.fundos.map(fund => fund));
        setRegistroBoleto(formatted.clienteContas[0]?.tipoRegistroBoleto);
        setSms(formatted.clienteContas[0].tokenPorTransacao);
        setAlcada(formatted.clienteContas[0].exigeAlcadaCredito);
        setAccountInformation(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (currentInformation.gerenteComercial) {
      setGerente(currentInformation.gerenteComercial.nome);
    }
  }, [currentInformation, gerentesComerciais]);

  useEffect(() => {
    setESolidaria(currentInformation.clienteContas[0]?.assinaturaSolidaria);
    setTipoControle(currentInformation.clienteContas[0]?.tipoControle);
    setAssociadoId(
      currentInformation.clienteContas[0]?.usuarioAdministrador
        .idUsuarioAdministrador,
    );
    if (userList.length > 0) {
      setAssociado(
        JSON.stringify(
          currentInformation.clienteContas[0]?.usuarioAdministrador,
        ),
      );
    }
    if (administradores.length > 0) {
      setAdministradorValue(
        JSON.stringify(currentInformation.clienteContas[0]?.administrador),
      );
      setAdministradorId(
        JSON.stringify(
          currentInformation.clienteContas[0]?.administrador
            .idAdministradorFundo,
        ),
      );
    }
    if (selectSegmento.length > 0) {
      const valueSegmentoIf = JSON.stringify({
        codigoSegmento:
          currentInformation.clienteContas[0]?.segmento.codigoSegmento,
        descricaoSegmento:
          currentInformation.clienteContas[0]?.segmento.descricaoSegmento,
      });
      setSegmento(valueSegmentoIf);
    }
    setSegmentoId(currentInformation.clienteContas[0]?.segmento.codigoSegmento);
    setSituacao(currentInformation.clienteContas[0]?.situacao);
  }, [
    currentInformation,
    userList,
    selectSegmento,
    administradores,
    faixas,
    faixasBoleto,
    idAdmin,
  ]);

  useEffect(() => {
    listarUsuarios();
    listarAdministradores();
  }, []);

  useEffect(() => {
    if (idAdmin > 0) {
      listarFundos();
    }
  }, [idAdmin]);

  useEffect(() => {
    const setFundosUnicos = new Set();

    const filterFundo = allfundos?.filter(fundo => {
      const fundosDuplicados = setFundosUnicos.has(fundo.cnpj);
      setFundosUnicos.add(fundo.cnpj);
      return !fundosDuplicados;
    });
    const filteredFundos = filterFundo?.filter(item =>
      valorFundoTratado.some(x => x === item.cnpj),
    );

    setEditFundos(filteredFundos);
  }, [allfundos, valorDoFundo]);

  useEffect(() => {
    if (!!currentInformation.cnpjCpf) {
      api_multipague_conta
        .get(`/cliente/${currentInformation.cnpjCpf}/cedente`)
        .then(function (response) {
          const formatCedente = response.data.gerenteComercial.nome;
          setGerenteCedente(formatCedente);
        });
    }
  }, []);

  useEffect(() => {
    api_multipague_conta
      .get(`/cliente/gerentes-comerciais`)
      .then(function (response) {
        const formatGerentes = response.data.content.map(usr => {
          const value = JSON.stringify({
            id: usr.id,
            nome: usr.nome,
            cnpjCpf: usr.cnpjCpf,
          });
          return (
            <MenuItem key={usr.id} name={usr.cnpjCpf} value={value}>
              {usr.nome}
            </MenuItem>
          );
        });
        setGerentesComerciais(formatGerentes);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleFundos = event => {
    const {
      target: { value },
    } = event;
    const eventFiltered = allfundos.filter(objeto => {
      return value.includes(objeto.cnpj);
    });
    const eventFiltered2 = valorDoFundo.filter(objeto => {
      return value.includes(objeto.cnpj);
    });

    setValorDoFundo(
      typeof value === 'string'
        ? eventFiltered.concat(eventFiltered2).split(',')
        : eventFiltered.concat(eventFiltered2),
    );
  };

  function confirmBlock() {
    setLoadButton(true);
    const dataBloq = {
      motivo: motivoBloqueio,
    };
    const URL = `/cliente/${currentInformation?.id}/conta/${currentInformation?.clienteContas[0].id}/bloqueio?&observacao=${motivoBloqueio}`;
    api_multipague_conta
      .post(URL, JSON.stringify(dataBloq), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        setLoadButton(false);
        toastComponentSuccess('Conta bloqueada com sucesso!', theme);
        setTimeout(function () {
          window.location.href = '/backoffice/pendencias-abertura';
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          // alert(error.response.data.mensagem)
          toastComponentError(error.response.data.mensagem, theme);
          setLoadButton(false);
        }
      });
  }

  function confirmDesblock() {
    setLoadButton(true);
    const dataBloq = {
      motivo: motivoBloqueio,
    };
    const URL = `/cliente/${valorIdBloqueio}/conta/${currentInformation?.clienteContas[0].id}/desbloqueio?&observacao=${motivoBloqueio}`;
    api_multipague_conta
      .post(URL, JSON.stringify(dataBloq), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        setLoadButton(false);
        toastComponentSuccess('Conta desbloqueada com sucesso!', theme);
        setTimeout(function () {
          window.location.href = '/backoffice/pendencias-abertura';
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          // alert(error.response.data.mensagem)
          toastComponentError(error.response.data.mensagem, theme);
          setLoadButton(false);
        }
      });
  }

  async function listarUsuarios() {
    try {
      const { data } = await api_multipague_configuracao.get(
        `usuario/login/${valorIdBloqueio}?master=false`,
      );

      const formatted = data.map(usr => {
        const value = JSON.stringify({
          idUsuarioAdministrador: usr.id,
          nomeUsuarioAdministrador: usr.nome,
        });
        return (
          <MenuItem key={usr.id} name={usr.id} value={value}>
            {usr.nome}
          </MenuItem>
        );
      });
      setUserList(formatted);
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        theme,
      );
      console.log(err);
    }
  }

  async function listarAdministradores() {
    try {
      const { data } = await api_multipague_conta.get('conta/administradoras');

      const formatted = data.map(usr => {
        const value = JSON.stringify({
          idAdministradorFundo: usr.id,
          nomeAdministradorFundo: usr.descricao,
        });
        return (
          <MenuItem key={usr.id} name={usr.id} value={value}>
            {usr.descricao}
          </MenuItem>
        );
      });
      setAdministradores(formatted);
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        theme,
      );
      console.log(err);
    }
  }

  async function listarFundos() {
    try {
      const { data } = await api_multipague_conta.get(
        `conta/administradoras/${idAdmin}/fundos`,
      );

      const formatted = data.map(name => {
        const value = {
          cnpj: name.cnpj,
          id: name.id,
          idCadastroOrigem: name.idCadastroOrigem,
          nome: name.nome,
        };
        return value;
      });
      setFundos(formatted);
      setAllFundos(prev => [...prev, ...formatted]);
    } catch (err) {
      toastComponentError(
        ` Codigo: ${err.response.data.codigo} - ${err.response.data.mensagem}`,
        theme,
      );
    }
  }

  async function salvarEdicao() {
    setLoadButton(true);
    const emptyFields = [
      {
        'CPF/CNPJ': currentInformation.cnpjCpf,
        Nome: currentInformation.pessoa.razaoSocial,
        'Nome Fantasia': currentInformation.pessoa.nomeFantasia,
        'Data de Abertura': currentInformation?.clienteContas[0]?.dataAbertura,
        'Tipo de Controle': currentInformation.clienteContas[0]?.tipoControle,
        'Usuario Administrador':
          currentInformation.clienteContas[0]?.usuarioAdministrador,
        codigoSegmento:
          currentInformation.clienteContas[0]?.segmento.codigoSegmento,
        'Cliente Correntista - CPF':
          currentInformation.clienteCorrentistas[0]?.cpf,
        'Cliente Correntista - RG':
          currentInformation.clienteCorrentistas[0]?.rg,
        'Cliente Correntista - nome':
          currentInformation.clienteCorrentistas[0]?.nome,
        'Cliente Correntista - email':
          currentInformation.clienteCorrentistas[0]?.contato?.email,
        'Cliente Correntista - DDD':
          currentInformation.clienteCorrentistas[0]?.contato?.ddd,
        'Cliente Correntista - Telefone':
          currentInformation.clienteCorrentistas[0]?.contato?.telefone,
        CEP: currentInformation.pessoa?.pessoasEndereco[0]?.cep,
        Endereco: currentInformation.pessoa?.pessoasEndereco[0]?.endereco,
        Número: currentInformation.pessoa?.pessoasEndereco[0]?.enderecoNum,
        Bairro: currentInformation.pessoa?.pessoasEndereco[0]?.bairro,
        Cidade: currentInformation.pessoa?.pessoasEndereco[0]?.cidade,
        UF: currentInformation.pessoa?.pessoasEndereco[0]?.uf,
        tipoEndereco:
          currentInformation.pessoa?.pessoasEndereco[0]?.tipoEndereco,
        tipoResidencia:
          currentInformation.pessoa?.pessoasEndereco[0]?.tipoResidencia,
      },
    ];

    const keysWithEmptyValues = [];

    emptyFields.map(item => {
      for (const key in item) {
        if (item[key] === '' || item[key] === undefined || item[key] === null) {
          keysWithEmptyValues.push(key);
        }
      }
    });

    setArrayEmptys(keysWithEmptyValues);

    const errorCamposObrigatorios = () => {
      setLoadButton(false);
      toastComponentError(
        `Campos obrigatórios não preenchidos. Observe os campos em vermelho.`,
        theme,
      );
    };

    const AuthStr = 'Bearer '.concat(tknAccess);
    const URL = `/cliente`;

    const body = {
      ...currentInformation,
      clienteContas: currentInformation.clienteContas.map(account => ({
        administrador: {
          idAdministradorFundo: account.administrador.idAdministradorFundo,
          nomeAdministradorFundo: account.administrador.nomeAdministradorFundo,
        },
        apelidoConta: account.apelidoConta,
        dataAbertura: moment(account.dataAbertura).format(
          'YYYY-MM-DDT00:00:00.000',
        ),
        assinaturaSolidaria: account.assinaturaSolidaria,
        tipoRegistroBoleto: registroBoleto,
        id: account.id,
        tipoControle: account.tipoControle,
        segmento: {
          id: account.segmento.codigoSegmento,
          descricao: account.segmento.descricaoSegmento,
        },
        usuarioAdministrador: {
          idUsuarioAdministrador:
            account.usuarioAdministrador.idUsuarioAdministrador,
          nomeUsuarioAdministrador:
            account.usuarioAdministrador.nomeUsuarioAdministrador,
        },
        notificaTransacoesPendentes:
          account.notificaTransacoesPendentes == true ? true : false,
        fundos: editFundos,
        tokenPorTransacao: sms == true ? true : false,
        exigeAlcadaCredito: alcada == true ? true : false,
      })),
    };

    keysWithEmptyValues.length <= 0
      ? api_multipague_conta
          .put(URL, body)
          .then(function (response) {
            setLoadButton(false);
            toastComponentSuccess('Dados salvos com sucesso!', theme);
            setTimeout(function () {
              window.location.href = '/backoffice/pendencias-abertura';
            }, 5000);
          })
          .catch(function (error) {
            setLoadButton(false);
            if (
              error.response.status == 400 ||
              error.response.status == 404 ||
              error.response.status == 409 ||
              error.response.status == 500
            ) {
              toastComponentError(error.response.data.mensagem, theme);
            }
          })
      : errorCamposObrigatorios();
  }

  return (
    <Wrapper
      backButton
      title="Detalhes do cliente"
      crumb={[{ link: '/backoffice', name: 'Home' }]}
    >
      <Container maxWidth="lg" className={classes.container}>
        <React.Fragment>
          <Box className={classes.boxContainer}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginBottom: '20px', marginRight: '20px' }}
                >
                  {`Dados da Pessoa ${
                    currentInformation.cnpjCpf.length < 14
                      ? 'Física'
                      : 'Jurídica'
                  }`}
                </Typography>
                {currentInformation?.cnpjCpf === '' ? (
                  <CircularProgress size={28} />
                ) : null}
              </div>
              <div>
                {situacao === 'BLOQUEADA' ? (
                  <FormGroup row>
                    <LoadingBtn
                      type="submit"
                      variant="contained"
                      title={'Desbloquer Conta'}
                      color="primary"
                      isLoading={loadButton}
                      startIcon={
                        !loadButton && (
                          <LockOpenIcon
                            style={{
                              width: 25,
                              color: theme.palette.background.paper,
                            }}
                          />
                        )
                      }
                      onClick={confirmDesblock}
                    >
                      Desbloquear Conta
                    </LoadingBtn>
                  </FormGroup>
                ) : (
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={state.checkedA}
                          onChange={handleChange}
                          name="checkedA"
                        />
                      }
                      label="Bloquear Conta"
                    />
                  </FormGroup>
                )}
              </div>
            </div>
            {clienteCorrentista2Cpf != '' ? (
              <Grid container spacing={3} style={{ marginBottom: '40px' }}>
                <Grid item xs={12} sm={12}>
                  <Alert severity="info">Esta é uma Assinatura Conjunta</Alert>
                </Grid>
              </Grid>
            ) : (
              ''
            )}

            <TextField
              fullWidth
              label="Motivo do bloqueio"
              className={
                state.checkedA === false ? classes.inputBloq : classes.inputFree
              }
              multiline
              rows={4}
              variant="outlined"
              placeholder=""
              onChange={e => {
                setMotivoBloqueio(e.target.value);
              }}
            />

            <Grid
              container
              spacing={3}
              className={
                state.checkedA === false ? classes.inputBloq : classes.inputFree
              }
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{ textAlign: 'center', marginBottom: '20px' }}
              >
                <br />
                <LoadingBtn
                  type="submit"
                  style={{
                    textTransform: 'none',
                    margin: 'auto',
                    width: 'auto',
                  }}
                  title={'Confirmar Bloqueio'}
                  isLoading={loadButton}
                  startIcon={<MdSave color="background" />}
                  onClick={confirmBlock}
                >
                  Confirmar Bloqueio
                </LoadingBtn>
              </Grid>
              <ToastContainer />
            </Grid>

            {situacao === 'BLOQUEADA' ? (
              <Grid container spacing={3} style={{ marginBottom: '40px' }}>
                <Grid item xs={12} sm={12}>
                  <Alert severity="error">Conta BLOQUEADA!</Alert>
                </Grid>
              </Grid>
            ) : (
              <div></div>
            )}

            <Grid container spacing={3} style={{ alignItems: 'center' }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="cnpjCpf"
                  error={arrayEmptys.includes('CPF/CNPJ')}
                  name="cnpjCpf"
                  label={
                    currentInformation.cnpjCpf.length < 14 ? 'CPF' : 'CNPJ'
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.cnpjCpf}
                  onChange={e => {
                    setCurrentInformation({
                      ...currentInformation,
                      cnpjCpf: e.target.value,
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="razaoSocial"
                  name="razaoSocial"
                  error={arrayEmptys.includes('Nome')}
                  label={
                    currentInformation.cnpjCpf.length < 14
                      ? 'Nome'
                      : 'Razão Social'
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa.razaoSocial}
                  onChange={e => {
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        razaoSocial: e.target.value,
                      },
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  error={arrayEmptys.includes('Nome Fantasia')}
                  id="nomeFantasia"
                  name="nomeFantasia"
                  label="Nome Fantasia"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa.nomeFantasia}
                  onChange={e => {
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        nomeFantasia: e.target.value,
                      },
                    });
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <TextField
                  variant="outlined"
                  size="small"
                  id="apelidoConta"
                  name="apelidoConta"
                  label="Apelido Conta"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation?.clienteContas[0]?.apelidoConta}
                  onChange={event =>
                    setCurrentInformation({
                      ...currentInformation,
                      clienteContas: currentInformation.clienteContas.map(
                        accountClient => ({
                          ...accountClient,
                          apelidoConta: event.target.value,
                        }),
                      ),
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <TextField
                  variant="outlined"
                  size="small"
                  id="inscEstadual"
                  name="inscEstadual"
                  label="Inscrição Estadual"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa.documentoEstadual}
                  onChange={e => {
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        documentoEstadual: e.target.value,
                      },
                    });
                  }}
                  fullWidth
                  autoComplete="shipping address-line2"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  id="inscMunicipal"
                  name="inscMunicipal"
                  label="Inscrição Municipal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa.documentoMunicipal}
                  onChange={e => {
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        documentoMunicipal: e.target.value,
                      },
                    });
                  }}
                  fullWidth
                  autoComplete="shipping address-level2"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="dataAbertura"
                  error={arrayEmptys.includes('Data de Abertura')}
                  type="date"
                  name="dataAbertura"
                  label="Data de Abertura"
                  value={currentInformation?.clienteContas[0]?.dataAbertura}
                  onChange={event => {
                    setCurrentInformation({
                      ...currentInformation,
                      clienteContas: currentInformation.clienteContas.map(
                        accountClient => ({
                          ...accountClient,
                          dataAbertura: event.target.value,
                        }),
                      ),
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  id="paginaWeb"
                  name="paginaWeb"
                  label="Página Web"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa.pessoaContatos[0].paginaWeb}
                  onChange={e => {
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoaContatos:
                          currentInformation.pessoa.pessoaContatos.map(
                            newContact => ({
                              ...newContact,
                              paginaWeb: e.target.value,
                            }),
                          ),
                      },
                    });
                  }}
                  fullWidth
                />
              </Grid>
              {currentInformation.gerenteComercial && !gerenteCedente ? (
                <Grid
                  item
                  xs={12}
                  md={6}
                  sm={6}
                  style={{ alignContent: 'center' }}
                >
                  <InputLabel shrink htmlFor="outlined-age-native-simple">
                    Gerente Comercial
                  </InputLabel>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullwidth
                    className={classes.formControl}
                    error={!currentInformation.gerenteComercial.id}
                  >
                    <Select
                      displayEmpty
                      labelId={
                        currentInformation.gerenteComercial.id
                          ? ''
                          : 'demo-simple-select-error-label'
                      }
                      value={currentInformation.gerenteComercial.nome}
                      onChange={e => {
                        setCurrentInformation({
                          ...currentInformation,
                          gerenteComercial: JSON.parse(e.target.value),
                        });
                      }}
                    >
                      <MenuItem
                        selected
                        value={currentInformation.gerenteComercial.nome}
                      >
                        {currentInformation.gerenteComercial.nome}
                      </MenuItem>
                      {gerentesComerciais}
                    </Select>
                    {currentInformation.gerenteComercial.id == null && (
                      <FormHelperText>
                        Grave a página para validar
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ) : currentInformation.gerenteComercial && gerenteCedente ? (
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    alignmentBaseline: 'central',
                    alignContent: 'center',
                  }}
                >
                  <InputLabel shrink htmlFor="outlined-age-native-simple">
                    Selecione o Gerente Comercial
                  </InputLabel>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullwidth
                    className={classes.formControl}
                  >
                    <Select
                      displayEmpty
                      value={gerenteCedente}
                      onChange={e => {
                        setCurrentInformation({
                          ...currentInformation,
                          gerenteComercial:
                            currentInformation.gerenteComercial.map(
                              accountClient => ({
                                ...accountClient,
                                nome: e.target.value,
                              }),
                            ),
                        });
                      }}
                    >
                      <MenuItem value={gerenteCedente}>
                        {gerenteCedente}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={12} md={6}>
                  <InputLabel shrink htmlFor="outlined-age-native-simple">
                    Selecione o Gerente Comercial
                  </InputLabel>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullwidth
                    className={classes.formControl}
                  >
                    <Select
                      displayEmpty
                      value={varGerenteComercial}
                      onChange={e => {
                        setCurrentInformation({
                          ...currentInformation,
                          gerenteComercial: JSON.parse(e.target.value),
                        });
                      }}
                    >
                      {gerentesComerciais}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Box>

          <Box className={classes.boxContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div style={{ display: 'flex' }}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      style={{ marginRight: '20px' }}
                    >
                      Dados da conta
                    </Typography>
                    {currentInformation?.cnpjCpf === '' ? (
                      <CircularProgress size={28} />
                    ) : null}
                  </div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          currentInformation.clienteContas[0]
                            ?.notificaTransacoesPendentes
                            ? true
                            : false
                        }
                        onChange={event =>
                          setCurrentInformation({
                            ...currentInformation,
                            clienteContas: currentInformation.clienteContas.map(
                              accountClient => ({
                                ...accountClient,
                                notificaTransacoesPendentes:
                                  event.target.checked,
                              }),
                            ),
                          })
                        }
                      />
                    }
                    label="Notifica transações pendentes"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={sms}
                        onChange={event => setSms(event.target.checked)}
                      />
                    }
                    label="Permitir envio de SMS Token"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  disabled
                  size="small"
                  variant="outlined"
                  required
                  id="banco"
                  name="banco"
                  label="Banco"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.clienteContas[0]?.banco}
                  fullWidth
                  autoComplete="shipping postal-code"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  required
                  id="agencia"
                  name="agencia"
                  label="Agencia"
                  value={currentInformation.clienteContas[0]?.agencia}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  required
                  id="conta"
                  name="conta"
                  label="Conta"
                  value={currentInformation.clienteContas[0]?.conta}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  autoComplete="shipping postal-code"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  required
                  id="dgconta"
                  name="dgconta"
                  label="Digito Conta"
                  value={currentInformation.clienteContas[0]?.digitoConta}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  autoComplete="shipping postal-code"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel shrink htmlFor="outlined-age-native-simple">
                  Tipo de Controle *
                </InputLabel>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullwidth
                  required
                  className={classes.formControl}
                >
                  <Select
                    displayEmpty
                    required
                    value={tipoControle}
                    onChange={e => {
                      setPreencheuControle(true);
                      setCurrentInformation({
                        ...currentInformation,
                        clienteContas: currentInformation.clienteContas.map(
                          accountClient => ({
                            ...accountClient,
                            tipoControle: e.target.value,
                          }),
                        ),
                      });
                    }}
                  >
                    <MenuItem value="INTERNO">Interno (Multiplica)</MenuItem>
                    <MenuItem value="EXTERNO">Externo (Cliente)</MenuItem>
                    <MenuItem value="ESCROW">Escrow</MenuItem>
                    <MenuItem value="ADM_FOLHA">Adm Folha</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {tipoControle != 'EXTERNO' && (
                <Grid item xs={12} md={4}>
                  <InputLabel shrink htmlFor="outlined-age-native-associado0">
                    Associado à *:{' '}
                  </InputLabel>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullwidth
                    required
                    className={classes.formControl}
                  >
                    <Select
                      displayEmpty
                      required
                      value={associado}
                      name={
                        currentInformation.clienteContas[0]
                          ?.usuarioAdministrador.idUsuarioAdministrador
                      }
                      onChange={e => {
                        setNewName(e.target.name);
                        setCurrentInformation({
                          ...currentInformation,
                          clienteContas: currentInformation.clienteContas.map(
                            accountClient => ({
                              ...accountClient,
                              usuarioAdministrador: JSON.parse(e.target.value),
                            }),
                          ),
                        });
                      }}
                    >
                      {userList}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {tipoControle === 'ESCROW' && (
                <Grid item xs={12} md={4}>
                  <InputLabel shrink htmlFor="outlined-age-native-simple2">
                    Administradora Vinculada *:
                  </InputLabel>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullwidth
                    className={classes.formControl}
                  >
                    <Select
                      displayEmpty
                      defaultValue={fundoValue}
                      onChange={e => {
                        setAdministradorId(
                          JSON.parse(e.target.value)?.idAdministradorFundo,
                        );
                        setCurrentInformation({
                          ...currentInformation,
                          clienteContas: currentInformation.clienteContas.map(
                            accountClient => ({
                              ...accountClient,
                              administrador: JSON.parse(e.target.value),
                            }),
                          ),
                        });
                      }}
                    >
                      <MenuItem value="" selected>
                        <em>
                          {
                            currentInformation.clienteContas[0]?.administrador
                              .nomeAdministradorFundo
                          }
                        </em>
                      </MenuItem>
                      {administradores}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {idAdmin > 0 && fundos.length > 0 && tipoControle === 'ESCROW' ? (
                <Grid item xs={12} md={4}>
                  <InputLabel shrink htmlFor="demo-multiple-checkbox">
                    Fundos Vinculados:
                  </InputLabel>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullwidth
                    className={classes.formControl}
                  >
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={valorFundoTratado}
                      onChange={handleFundos}
                      renderValue={selected => {
                        const fundosSelecionados = fundos.filter(fundo =>
                          selected.includes(fundo.cnpj),
                        );
                        return fundosSelecionados
                          .map(fundo => fundo.nome)
                          .join(', ');
                      }}
                    >
                      {fundos.map(fundo => (
                        <MenuItem key={fundo.cnpj} value={fundo.cnpj}>
                          <Checkbox
                            checked={valorFundoTratado.includes(fundo.cnpj)}
                          />
                          <ListItemText primary={fundo.nome} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item xs={12} md={4}>
                <InputLabel
                  shrink
                  htmlFor="outlined-age-native-simple-seg-zero"
                >
                  Segmento da Conta *
                </InputLabel>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullwidth
                  className={classes.formControl}
                >
                  <Select
                    displayEmpty
                    value={segmento}
                    error={arrayEmptys.includes('codigoSegmento')}
                    onChange={e => {
                      setPreencheuSegmento(true);
                      setCurrentInformation({
                        ...currentInformation,
                        clienteContas: currentInformation.clienteContas.map(
                          accountClient => ({
                            ...accountClient,
                            segmento: JSON.parse(e.target.value),
                          }),
                        ),
                      });
                    }}
                  >
                    {selectSegmento}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  alignSelf: 'center',
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    marginRight: '1rem',
                  }}
                >
                  Tipo de Registro de Boleto *:
                </Typography>
                <FormControlLabel
                  control={
                    <>
                      <Checkbox
                        color="primary"
                        checked={registroBoleto === 'ONLINE'}
                        onChange={() => setRegistroBoleto('ONLINE')}
                      />
                      <InputLabel>Online</InputLabel>
                      <Checkbox
                        color="primary"
                        checked={registroBoleto === 'OFFLINE'}
                        onChange={() => setRegistroBoleto('OFFLINE')}
                      />
                      <InputLabel>Offline</InputLabel>
                    </>
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={alcada}
                      onChange={event => setAlcada(event.target.checked)}
                    />
                  }
                  label="Exige Alçada Crédito"
                />
              </Grid>
            </Grid>
            {/** mostrarTarifas && <div>{listaTarifas}</div> ***/}
          </Box>
          <Box className={classes.boxContainer}>
            <div style={{ display: 'flex' }}>
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginRight: '20px' }}
              >
                Dados do Representante
              </Typography>
              {currentInformation?.cnpjCpf === '' ? (
                <CircularProgress size={28} />
              ) : null}
            </div>
            <Grid container spacing={3} style={{ alignItems: 'center' }}>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={eSolidaria}
                      // onChange={event => setChecked(event.target.checked)}
                      onChange={event =>
                        setCurrentInformation({
                          ...currentInformation,
                          clienteContas: currentInformation.clienteContas.map(
                            accountClient => ({
                              ...accountClient,
                              assinaturaSolidaria: event.target.checked,
                            }),
                          ),
                        })
                      }
                    />
                  }
                  label="Assinatura solidária (representantes assinam em conjunto)"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  disabled={!perfilMaster()}
                  variant="outlined"
                  size="small"
                  required
                  id="cpf"
                  name="cpf"
                  label="CPF"
                  value={currentInformation.clienteCorrentistas[0]?.cpf.replace(
                    /[.-]/g,
                    '',
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      clienteCorrentistas:
                        currentInformation.clienteCorrentistas.map(
                          (correntistClient, index) => {
                            if (index === 0) {
                              return {
                                ...correntistClient,
                                cpf: e.target.value.replace(/[.-]/g, ''),
                              };
                            } else {
                              return {
                                ...correntistClient,
                              };
                            }
                          },
                        ),
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="rg"
                  name="rg"
                  label="RG"
                  value={currentInformation.clienteCorrentistas[0]?.rg}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      clienteCorrentistas:
                        currentInformation.clienteCorrentistas.map(
                          (correntistClient, index) => {
                            if (index === 0) {
                              return {
                                ...correntistClient,
                                rg: e.target.value,
                              };
                            } else {
                              return {
                                ...correntistClient,
                              };
                            }
                          },
                        ),
                    })
                  }
                  fullWidth
                  autoComplete="shipping postal-code"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="nome"
                  name="nome"
                  label="Nome"
                  value={currentInformation.clienteCorrentistas[0]?.nome}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      clienteCorrentistas:
                        currentInformation.clienteCorrentistas.map(
                          (correntistClient, index) => {
                            if (index === 0) {
                              return {
                                ...correntistClient,
                                nome: e.target.value,
                              };
                            } else {
                              return {
                                ...correntistClient,
                              };
                            }
                          },
                        ),
                    })
                  }
                  fullWidth
                  autoComplete="shipping postal-code"
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="email"
                  name="email"
                  label="Email"
                  value={
                    currentInformation.clienteCorrentistas[0]?.contato?.email
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      clienteCorrentistas:
                        currentInformation.clienteCorrentistas.map(
                          (contactPeople, index) => {
                            if (index === 0) {
                              return {
                                ...contactPeople,
                                contato: {
                                  ...contactPeople.contato,
                                  email: e.target.value,
                                },
                              };
                            } else {
                              return {
                                ...contactPeople,
                              };
                            }
                          },
                        ),
                    })
                  }
                  fullWidth
                  autoComplete="shipping postal-code"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  id="redeSocial"
                  label="Rede Social"
                  fullWidth
                  name="redeSocial"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    currentInformation.clienteCorrentistas[0]?.contato
                      ?.redeSocial
                  }
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      clienteCorrentistas:
                        currentInformation.clienteCorrentistas.map(
                          (contactPeople, index) => {
                            if (index === 0) {
                              return {
                                ...contactPeople,
                                contato: {
                                  ...contactPeople.contato,
                                  redeSocial: e.target.value,
                                },
                              };
                            } else {
                              return {
                                ...contactPeople,
                              };
                            }
                          },
                        ),
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={1}>
                <TextField
                  inputComponent={TextMaskCustomDDD}
                  variant="outlined"
                  size="small"
                  required
                  error={arrayEmptys.includes('Cliente Correntista - DDD')}
                  label="DDD"
                  name="ddd1"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    currentInformation.clienteCorrentistas[0]?.contato?.ddd
                  }
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      clienteCorrentistas:
                        currentInformation.clienteCorrentistas.map(
                          (contactPeople, index) => {
                            if (index === 0) {
                              return {
                                ...contactPeople,
                                contato: {
                                  ...contactPeople.contato,
                                  ddd: e.target.value,
                                },
                              };
                            } else {
                              return {
                                ...contactPeople,
                              };
                            }
                          },
                        ),
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  error={arrayEmptys.includes('Cliente Correntista - Telefone')}
                  label="Telefone"
                  name="telefone1"
                  fullWidth
                  inputComponent={TextMaskCustomCel}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    currentInformation.clienteCorrentistas[0]?.contato?.telefone
                  }
                  onBlur={e => {
                    setEcelular(e.target.value.length > 8);
                  }}
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      clienteCorrentistas:
                        currentInformation.clienteCorrentistas.map(
                          (contactPeople, index) => {
                            if (index === 0) {
                              return {
                                ...contactPeople,
                                contato: {
                                  ...contactPeople.contato,
                                  telefone: e.target.value,
                                },
                              };
                            } else {
                              return {
                                ...contactPeople,
                              };
                            }
                          },
                        ),
                    })
                  }
                />
              </Grid>
            </Grid>
            {accountInformation?.clienteCorrentistas?.length > 1 ||
            showNovoCorrentista ? (
              <Grid container spacing={3} style={{ marginTop: '20px' }}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6" gutterBottom>
                    Dados do Segundo Representante
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    disabled
                    variant="outlined"
                    size="small"
                    required
                    id="cpf"
                    name="cpf"
                    label="CPF"
                    error={arrayEmptys.includes('Cliente Correntista - CPF')}
                    value={currentInformation.clienteCorrentistas[1]?.cpf}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    id="rg"
                    name="rg"
                    error={arrayEmptys.includes('Cliente Correntista - RG')}
                    label="RG"
                    value={currentInformation.clienteCorrentistas[1]?.rg}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e =>
                      setCurrentInformation({
                        ...currentInformation,
                        clienteCorrentistas:
                          currentInformation.clienteCorrentistas.map(
                            (correntistClient, index) => {
                              if (index === 1) {
                                return {
                                  ...correntistClient,
                                  rg: e.target.value,
                                };
                              } else {
                                return {
                                  ...correntistClient,
                                };
                              }
                            },
                          ),
                      })
                    }
                    fullWidth
                    autoComplete="shipping postal-code"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    id="nome"
                    error={arrayEmptys.includes('Cliente Correntista - nome')}
                    name="nome"
                    label="Nome"
                    value={currentInformation.clienteCorrentistas[1]?.nome}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e =>
                      setCurrentInformation({
                        ...currentInformation,
                        clienteCorrentistas:
                          currentInformation.clienteCorrentistas.map(
                            (correntistClient, index) => {
                              if (index === 1) {
                                return {
                                  ...correntistClient,
                                  nome: e.target.value,
                                };
                              } else {
                                return {
                                  ...correntistClient,
                                };
                              }
                            },
                          ),
                      })
                    }
                    fullWidth
                    autoComplete="shipping postal-code"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    id="email"
                    error={arrayEmptys.includes('Cliente Correntista - email')}
                    name="email"
                    label="Email"
                    value={
                      currentInformation.clienteCorrentistas[1]?.contato?.email
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    autoComplete="shipping postal-code"
                    onChange={e =>
                      setCurrentInformation({
                        ...currentInformation,
                        clienteCorrentistas:
                          currentInformation.clienteCorrentistas.map(
                            (contactPeople, index) => {
                              if (index === 1) {
                                return {
                                  ...contactPeople,
                                  contato: {
                                    ...contactPeople.contato,
                                    email: e.target.value,
                                  },
                                };
                              } else {
                                return {
                                  ...contactPeople,
                                };
                              }
                            },
                          ),
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    size="small"
                    id="redeSocial"
                    label="Rede Social"
                    fullWidth
                    name="redeSocial"
                    error={arrayEmptys.includes('Cliente Correntista - email')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      currentInformation.clienteCorrentistas[1]?.contato
                        ?.redeSocial
                    }
                    onChange={e =>
                      setCurrentInformation({
                        ...currentInformation,
                        clienteCorrentistas:
                          currentInformation.clienteCorrentistas.map(
                            (contactPeople, index) => {
                              if (index === 1) {
                                return {
                                  ...contactPeople,
                                  contato: {
                                    ...contactPeople.contato,
                                    redeSocial: e.target.value,
                                  },
                                };
                              } else {
                                return {
                                  ...contactPeople,
                                };
                              }
                            },
                          ),
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <TextField
                    inputComponent={TextMaskCustomDDD}
                    variant="outlined"
                    size="small"
                    required
                    label="DDD"
                    name="ddd1"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      currentInformation.clienteCorrentistas[1]?.contato?.ddd
                    }
                    onChange={e =>
                      setCurrentInformation({
                        ...currentInformation,
                        clienteCorrentistas:
                          currentInformation.clienteCorrentistas.map(
                            (contactPeople, index) => {
                              if (index === 1) {
                                return {
                                  ...contactPeople,
                                  contato: {
                                    ...contactPeople.contato,
                                    ddd: e.target.value,
                                  },
                                };
                              } else {
                                return {
                                  ...contactPeople,
                                };
                              }
                            },
                          ),
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    label="Telefone"
                    name="telefone1"
                    inputComponent={TextMaskCustomCel}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      currentInformation.clienteCorrentistas[1]?.contato
                        ?.telefone
                    }
                    onChange={e =>
                      setCurrentInformation({
                        ...currentInformation,
                        clienteCorrentistas:
                          currentInformation.clienteCorrentistas.map(
                            (contactPeople, index) => {
                              if (index === 1) {
                                return {
                                  ...contactPeople,
                                  contato: {
                                    ...contactPeople.contato,
                                    telefone: e.target.value,
                                  },
                                };
                              } else {
                                return {
                                  ...contactPeople,
                                };
                              }
                            },
                          ),
                      })
                    }
                  />
                </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Box>
          <Box className={classes.boxContainer}>
            <div style={{ display: 'flex' }}>
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginBottom: '20px', marginRight: '20px' }}
              >
                Dados de Endereço
              </Typography>
              {currentInformation?.cnpjCpf === '' ? (
                <CircularProgress size={28} />
              ) : null}
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="cep"
                  label="CEP"
                  fullWidth
                  name="cep"
                  error={arrayEmptys.includes('CEP')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa?.pessoasEndereco[0]?.cep}
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoasEndereco:
                          currentInformation.pessoa.pessoasEndereco.map(
                            (address, index) => {
                              if (index === 0) {
                                return {
                                  ...address,
                                  cep: e.target.value,
                                };
                              } else {
                                return {
                                  ...address,
                                };
                              }
                            },
                          ),
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="logradouro"
                  label="Logradouro"
                  fullWidth
                  name="logradouro"
                  error={arrayEmptys.includes('Endereco')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    currentInformation.pessoa?.pessoasEndereco[0]?.endereco
                  }
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoasEndereco:
                          currentInformation.pessoa.pessoasEndereco.map(
                            (address, index) => {
                              if (index === 0) {
                                return {
                                  ...address,
                                  endereco: e.target.value,
                                };
                              } else {
                                return {
                                  ...address,
                                };
                              }
                            },
                          ),
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  id="complemento"
                  label="Complemento"
                  fullWidth
                  name="complemento"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    currentInformation.pessoa?.pessoasEndereco[0]?.complemento
                  }
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoasEndereco:
                          currentInformation.pessoa.pessoasEndereco.map(
                            (address, index) => {
                              if (index === 0) {
                                return {
                                  ...address,
                                  complemento: e.target.value,
                                };
                              } else {
                                return {
                                  ...address,
                                };
                              }
                            },
                          ),
                      },
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="numero"
                  label="Número"
                  error={arrayEmptys.includes('Número')}
                  fullWidth
                  name="numero"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    currentInformation.pessoa?.pessoasEndereco[0]?.enderecoNum
                  }
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoasEndereco:
                          currentInformation.pessoa.pessoasEndereco.map(
                            (address, index) => {
                              if (index === 0) {
                                return {
                                  ...address,
                                  enderecoNum: e.target.value,
                                };
                              } else {
                                return {
                                  ...address,
                                };
                              }
                            },
                          ),
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="bairro"
                  label="Bairro"
                  error={arrayEmptys.includes('Bairro')}
                  fullWidth
                  name="bairro"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa?.pessoasEndereco[0]?.bairro}
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoasEndereco:
                          currentInformation.pessoa.pessoasEndereco.map(
                            (address, index) => {
                              if (index === 0) {
                                return {
                                  ...address,
                                  bairro: e.target.value,
                                };
                              } else {
                                return {
                                  ...address,
                                };
                              }
                            },
                          ),
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="cidade"
                  label="Cidade"
                  fullWidth
                  name="cidade"
                  error={arrayEmptys.includes('Cidade')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa?.pessoasEndereco[0]?.cidade}
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoasEndereco:
                          currentInformation.pessoa.pessoasEndereco.map(
                            (address, index) => {
                              if (index === 0) {
                                return {
                                  ...address,
                                  cidade: e.target.value,
                                };
                              } else {
                                return {
                                  ...address,
                                };
                              }
                            },
                          ),
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  required
                  id="uf"
                  label="UF"
                  error={arrayEmptys.includes('UF')}
                  fullWidth
                  name="uf"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa?.pessoasEndereco[0]?.uf}
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoasEndereco:
                          currentInformation.pessoa.pessoasEndereco.map(
                            (address, index) => {
                              if (index === 0) {
                                return {
                                  ...address,
                                  uf: e.target.value,
                                };
                              } else {
                                return {
                                  ...address,
                                };
                              }
                            },
                          ),
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  size="small"
                  id="pais"
                  label="País"
                  fullWidth
                  name="pais"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={currentInformation.pessoa?.pessoasEndereco[0]?.pais}
                  onChange={e =>
                    setCurrentInformation({
                      ...currentInformation,
                      pessoa: {
                        ...currentInformation.pessoa,
                        pessoasEndereco:
                          currentInformation.pessoa.pessoasEndereco.map(
                            (address, index) => {
                              if (index === 0) {
                                return {
                                  ...address,
                                  pais: e.target.value,
                                };
                              } else {
                                return {
                                  ...address,
                                };
                              }
                            },
                          ),
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Tipo de Endereço *
                </InputLabel>
                <FormControl
                  variant="outlined"
                  size="small"
                  required
                  className={classes.formControl}
                  fullwidth
                >
                  <Select
                    required
                    displayEmpty
                    error={arrayEmptys.includes('tipoEndereco')}
                    value={
                      currentInformation.pessoa?.pessoasEndereco[0]
                        ?.tipoEndereco || ''
                    }
                    onChange={e =>
                      setCurrentInformation({
                        ...currentInformation,
                        pessoa: {
                          ...currentInformation.pessoa,
                          pessoasEndereco:
                            currentInformation.pessoa.pessoasEndereco.map(
                              (address, index) => {
                                if (index === 0) {
                                  return {
                                    ...address,
                                    tipoEndereco: e.target.value,
                                  };
                                } else {
                                  return {
                                    ...address,
                                  };
                                }
                              },
                            ),
                        },
                      })
                    }
                  >
                    <MenuItem value="RESIDENCIAL">Residencial</MenuItem>
                    <MenuItem value="COMERCIAL">Comercial</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Tipo de Residencia *
                </InputLabel>
                <FormControl
                  variant="outlined"
                  size="small"
                  required
                  className={classes.formControl}
                >
                  <Select
                    displayEmpty
                    required
                    error={arrayEmptys.includes('tipoResidencia')}
                    value={
                      currentInformation.pessoa?.pessoasEndereco[0]
                        ?.tipoResidencia || ''
                    }
                    onChange={e =>
                      setCurrentInformation({
                        ...currentInformation,
                        pessoa: {
                          ...currentInformation.pessoa,
                          pessoasEndereco:
                            currentInformation.pessoa.pessoasEndereco.map(
                              (address, index) => {
                                if (index === 0) {
                                  return {
                                    ...address,
                                    tipoResidencia: e.target.value,
                                  };
                                } else {
                                  return {
                                    ...address,
                                  };
                                }
                              },
                            ),
                        },
                      })
                    }
                  >
                    <MenuItem value="RESIDENCIA PROPRIA">Própria</MenuItem>
                    <MenuItem value="ALUGADA">Alugada</MenuItem>
                    <MenuItem value="FINANCIADA">Financiada</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <LoadingBtn
                  type="submit"
                  onClick={() =>
                    history.push('/backoffice/pendencias-abertura')
                  }
                  variant="contained"
                  title={'Cancelar'}
                  color="default"
                  style={{ marginRight: 5 }}
                  startIcon={<MdClear color="background" />}
                >
                  Cancelar
                </LoadingBtn>
                <LoadingBtn
                  type="submit"
                  variant="contained"
                  color="primary"
                  title={'Salvar'}
                  isLoading={loadButton}
                  startIcon={!loadButton && <MdSave color="background" />}
                  onClick={salvarEdicao}
                >
                  Salvar
                </LoadingBtn>
              </Grid>
            </Grid>
          </Box>
        </React.Fragment>
      </Container>
    </Wrapper>
  );
}
