import React from 'react';
import { DataGrid } from '@material-ui/data-grid';

import { ptBR } from './locale';

import { Container } from './style';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDataGrid-window': {
      backgroundColor:
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0)'
          : 'rgba(255, 255, 255, 0)',
    },
    '& .MuiDataGrid-overlay': {
      color: theme.palette.type === 'light' ? '#000' : '#fff',
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
  },
}));

export default ({
  headerComponent,
  rows,
  columns,
  pageSize,
  loading,
  height,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Container className={classes.root} elevation={2}>
      {headerComponent && (
        <div className="headerButtons">
          {React.createElement(headerComponent)}
        </div>
      )}
      <DataGrid
        rows={rows}
        disableColumnMenu
        columns={columns}
        pageSize={pageSize}
        loading={loading}
        style={{ height, boxShadow: 'none' }}
        {...rest}
        localeText={ptBR}
      />
    </Container>
  );
};
